import { JsonRpcSigner, Provider, Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { message } from "antd";
import { createContext, ReactChild, ReactChildren, useCallback, useContext, useEffect, useState } from "react";
import { useAsync, useLocalStorage } from "react-use";
import Web3Modal from "web3modal";
import { getNetworkById } from "../constants/network";
import { setRpcUrl as setLsRpcUrl } from "../helpers/env";
import Onboarding from "../starkex/onboaring";
import { SigningMethod, KeyPairWithYCoordinate } from "../starkex/onboardingTypes";
import { getVaultId } from "../api/vaultId";
import { hexStringWithoutLeadingZero } from "../starkex/helpers";
import { getAssets, getWithdrawAssets } from "../api/asset";

const targetNetworkId = Number(process.env.REACT_APP_NETWORK_ID) || 883;

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  network: "mainnet",
  providerOptions: {
    injected: {
      display: {
        // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
        name: "Injected",
        description: "Connect with the provider in your Browser",
      },
      package: null,
    },
    // Example with WalletConnect provider
    walletconnect: {
      display: {
        // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
        name: "Mobile",
        description: "Scan qrcode with your mobile wallet",
      },
      package: WalletConnectProvider,
      options: {
        infuraId: "INFURA_ID", // required
      },
    },
  },
  theme: "dark",
});

interface Web3ContextProps {
  provider: Provider | undefined;
  signer: JsonRpcSigner | undefined;
  network: string;
  address: string;
  rpcUrl: string;
  chainId: number;
  web3Modal: Web3Modal;
  connecting: boolean;
  starkKey: string | undefined;
  keyPairs: KeyPairWithYCoordinate | undefined;
  vaultIDs: Map<string, number>;
  loadWeb3Modal: (providerName: string) => Promise<void>;
  logoutOfWeb3Modal: () => Promise<void>;
}

interface Web3ContextProviderProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const Web3Context = createContext<Web3ContextProps>({
  provider: undefined,
  signer: undefined,
  address: "",
  rpcUrl: "",
  network: getNetworkById(targetNetworkId).name,
  chainId: 0,
  web3Modal,
  connecting: false,
  starkKey: undefined,
  keyPairs: undefined,
  vaultIDs: new Map<string, number>(),
  loadWeb3Modal: async () => {},
  logoutOfWeb3Modal: async () => {},
});

export const Web3ContextProvider = ({ children }: Web3ContextProviderProps): JSX.Element => {
  const networkObject = getNetworkById(targetNetworkId);
  const [provider, setProvider] = useState<Provider>();
  const [signer, setSigner] = useState<JsonRpcSigner>();
  const [network, setNetwork] = useState(networkObject.name);
  const [address, setAddress] = useState("");
  const [rpcUrl, setRpcUrl] = useState(networkObject.rpcUrl);
  const [chainId, setChainId] = useState(0);
  const [connecting, setConnecting] = useState(false);
  const [starkKey, setStarkKey] = useLocalStorage<string>("starkKey");
  const [keyPairs, setKeyPairs] = useLocalStorage<KeyPairWithYCoordinate>("keyPairs");
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [web3Connection, setWeb3Connection] = useState<any>();
  const [vaultIDs, setVaultIDs] = useState<Map<string, number>>(new Map());

  useEffect(() => {
    if (!starkKey) {
      return;
    }
    (async () => {
      const withdrawAssets = await getWithdrawAssets(address);
      withdrawAssets.forEach(it => {
        (async () => {
          await getVaultId(starkKey || "", it.tokenId);
        })();
      });
    })();
    (async () => {
      const assets = await getAssets(address);
      assets.forEach(it => {
        (async () => {
          const vaultID = await getVaultId(starkKey || "", it.tokenId);
          setVaultIDs(vaultIDs.set(it.tokenId, vaultID.vault_id ?? 0));
        })();
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, starkKey]);

  useAsync(async () => {
    if (!web3Connection) {
      return;
    }
    web3Connection.on("accountsChanged", () => {
      console.log("accountsChanged");

      setStarkKey("");
      setKeyPairs({ publicKey: "", privateKey: "", publicKeyYCoordinate: "" });
      window.location.reload();
    });
    web3Connection.on("chainChanged", () => {
      window.location.reload();
    });
  }, [web3Connection]);

  useAsync(async () => {
    if (!provider) {
      return;
    }
    const networkData = await provider.getNetwork();
    setChainId(networkData.chainId);
    setNetwork(networkData.name);
    const url = getNetworkById(Number(networkData.chainId)).rpcUrl;
    setRpcUrl(url);
    setLsRpcUrl(url);
  }, [provider]);

  const loadWeb3Modal = useCallback(
    async (providerName: string) => {
      setConnecting(true);
      const connection = await web3Modal.connectTo(providerName).catch(e => {
        console.error(e);
        setConnecting(false);
      });

      if (!connection) {
        message.error("connection failed!");
        return;
      }
      setConnecting(false);
      setWeb3Connection(connection);
      // TODO: Remove this workaround when possible
      if (connection.isImToken) {
        connection.request = undefined;
      }
      const newProvider = new Web3Provider(connection);
      setProvider(newProvider);
      const newSigner = newProvider.getSigner();
      setSigner(newSigner);
      const adrs = await newSigner.getAddress();
      setAddress(adrs);

      if (!starkKey) {
        const client = new Onboarding("", targetNetworkId, newSigner);
        const deriveKeys = await client.deriveStarkKey(SigningMethod.TypedData);
        const keyWithoutLeadingZero = hexStringWithoutLeadingZero(deriveKeys.publicKey);
        setStarkKey(keyWithoutLeadingZero);
        setKeyPairs(deriveKeys);
      }
    },
    [setKeyPairs, setStarkKey, starkKey],
  );

  const logoutOfWeb3Modal = useCallback(async () => {
    if (web3Connection && web3Connection.close) {
      web3Connection.close();
    }
    web3Modal.clearCachedProvider();
    setStarkKey("");
    setKeyPairs({ publicKey: "", privateKey: "", publicKeyYCoordinate: "" });
    window.location.reload();
  }, [web3Connection, setStarkKey, setKeyPairs]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal(web3Modal.cachedProvider);
    }
  }, [loadWeb3Modal]);

  return (
    <Web3Context.Provider
      value={{
        provider,
        signer,
        network,
        address,
        rpcUrl,
        chainId,
        web3Modal,
        connecting,
        starkKey,
        keyPairs,
        vaultIDs,
        loadWeb3Modal,
        logoutOfWeb3Modal,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3Context: () => Web3ContextProps = () => useContext(Web3Context);
