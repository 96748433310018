// import { Card } from "antd";
import { useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useLocalStorage } from "react-use";
import { InvestDetail, TokenList } from "../components";
// import LabelWithPopover from "../components/LabelWithPopover";
// import { formatUSD } from "../helpers/format";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchAssets, setSelectedToken, setSelectedTokenIndex } from "../redux/assetSlice";
import { fetchBalance } from "../redux/l2balanceSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { saveShow } from "../redux/tokenToggleSlice";
import { Theme, ThemeType } from "../theme/theme";

const useStyles = createUseStyles((theme: Theme) => {
  return {
    content: {
      display: "flex",
      width: "100%",
      background: "#222222",
      borderRadius: 12,
    },
    left: {
      borderRight: theme.borderLight,
    },
    investDetail: {
      flex: 1,
    },
    tvlCard: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: theme.fontSizeM,
      fontWeight: "bold",
    },
    tvl: {
      marginLeft: 6,
      color: theme.win,
      fontSize: theme.fontSizeL * 1.2,
      fontWeight: 600,
    },
  };
});

export default function Invest(): JSX.Element {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { address, starkKey } = useWeb3Context();
  const { asset } = useAppSelector(state => state);
  const { selectedIndex, loading: assetsLoading, assets } = asset;
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;
  const { IsShow } = useAppSelector(state => state.tokenToggle);
  const [lastSelectedToken, setLastSelectedToken] = useLocalStorage<number>("lastSelectedToken");

  useEffect(() => {
    if ((starkKey?.length ?? 0) === 0) {
      return;
    }
    dispatch(fetchBalance(starkKey || ""));
    dispatch(fetchAssets(address));
  }, [dispatch, address, starkKey]);

  useEffect(() => {
    if (selectedIndex === 1000 && !assetsLoading && assets.length) {
      if (lastSelectedToken) {
        dispatch(setSelectedToken(lastSelectedToken));
      } else {
        dispatch(setSelectedTokenIndex(0));
      }
    }
  }, [dispatch, selectedIndex, lastSelectedToken, assetsLoading, assets]);

  const handleSelectToken = (id: number) => {
    dispatch(setSelectedToken(id));
    setLastSelectedToken(id);
    dispatch(saveShow({ IsShow: false }));
  };

  return (
    <>
      {/* {chainId === 1 && (
        <Card
          bodyStyle={{
            padding: 12,
            background: theme.bgColorSecondary,
            borderRadius: theme.borderRadiusS,
          }}
          style={{ marginBottom: 12 }}
        >
          <div className={classes.tvlCard}>
            <LabelWithPopover label="Total Value Locked:" iconOnLeft>
              This is the total value locked in the Layer2.Finance protocol, including the total value that has been
              committed to strategies and the total unallocated L2 balance locked in the rollup chain.
            </LabelWithPopover>
            {!assetsLoading ? (
              <span className={classes.tvl}>{formatUSD(tvl.toFixed(0) + "")}</span>
            ) : (
              <span className={classes.tvl}>$--</span>
            )}
          </div>
        </Card>
      )} */}
      {!isMobile ? (
        <div className={classes.content}>
          <div className={classes.left}>
            <TokenList onSelectToken={handleSelectToken} />
          </div>
          <div className={classes.investDetail}>
            <InvestDetail />
          </div>
        </div>
      ) : (
        <div>
          <InvestDetail />
          <div style={IsShow ? { display: "block" } : { display: "none" }}>
            <TokenList onSelectToken={handleSelectToken} />
          </div>
        </div>
      )}
    </>
  );
}
