import { colorPallets } from "./colorPallets";

export enum ThemeType {
  XL = 1,
  S = 2,
}

const themeBase = {
  type: ThemeType.XL,

  fontSizeS: 12,
  fontSizeM: 14,
  fontSizeL: 16,
  fontSizeXL: 18,

  iconSizeL: 20,

  fontWeightLight: 200,
  fontWeightNormal: 400,
  fontWeightBold: 600,
  fontWeightBolder: 800,

  border: "1px solid #555",
  borderLight: "1px solid #2B2B2B",
  borderLighter: "1px solid #eee",

  borderRadiusXL: 24,
  borderRadiusL: 16,
  borderRadiusM: 14,
  borderRadiusS: 12,

  cardPaddingXL: 32,
  cardPaddingL: 24,
  cardPaddingM: 16,
  cardPaddingS: 12,

  highlightedText: {
    color: "#ffffff",
    fontWeight: "bold",
  },

  contentMaxWidth: 1376,
  contentMinWidth: 1200,
  xsFlex: "flex",
  balanceUnit: {
    height: "unset" as string | number,
    borderTop: "none",
    lineHeight: "",
    marginRight: 50,
  },
  countmarginLeft: "auto",
  apypadding: "4px 8px",
  actflexDirection: "column",
  invlexDirection: "column",
  actalignItems: "flex-end",
  modalbackground: "#141414",
  contentBackground: "#3a3a3a",
  hismainMargin: "14px -14px -14px -14px",
  apyLine: "none" as string | number,
  modalBottom: "24px",
};

const theme = { ...themeBase, ...colorPallets };

export type ThemeBase = typeof themeBase;

export type Theme = typeof theme;

export default theme;
