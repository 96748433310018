import BN from 'bn.js';
import { stripHexPrefix } from './helpers';

/**
 * Convert a hex string with optional 0x prefix to a BN.
 */
 export function hexToBn(hex: string): BN {
    return new BN(stripHexPrefix(hex), 16);
}
  
/**
 * Convert a BN to a 32-byte hex string without 0x prefix.
 */
 export function bnToHex32(bn: BN): string {
    return normalizeHex32(bn.toString(16));
  }

  /**
 * Normalize to a lowercase 32-byte hex string without 0x prefix.
 */
export function normalizeHex32(hex: string): string {
    const paddedHex = stripHexPrefix(hex).toLowerCase().padStart(64, '0');
    if (paddedHex.length !== 64) {
      throw new Error('normalizeHex32: Input does not fit in 32 bytes');
    }
    return paddedHex;
  }
  