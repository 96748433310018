import {
    elliptic
} from 'elliptic';
import { starkEc } from './signature';

import { bnToHex32, normalizeHex32 } from "./util";
import {
    KeyPairWithYCoordinate,
    KeyPair
} from "./onboardingTypes"


/**
 * Converts an `elliptic` KeyPair object to a simple object with publicKey & privateKey hex strings.
 *
 * Returns keys as 32-byte hex strings without 0x prefix.
 */
export function asSimpleKeyPair(
    ecKeyPair: elliptic.ec.KeyPair,
): KeyPairWithYCoordinate {
    const ecPrivateKey = ecKeyPair.getPrivate();
    if (!ecPrivateKey) {
        throw new Error('asSimpleKeyPair: Key pair has no private key');
    }
    const ecPublicKey = ecKeyPair.getPublic();
    return {
        publicKey: bnToHex32(ecPublicKey.getX()),
        publicKeyYCoordinate: bnToHex32(ecPublicKey.getY()),
        privateKey: bnToHex32(ecPrivateKey),
    };
}

/**
 * Helper for if you want to access additional cryptographic functionality with a private key.
 *
 * Accepts a private key or key pair as hex strings (with or without 0x prefix).
 */
export function asEcKeyPair(
    privateKeyOrKeyPair: string | KeyPair,
): elliptic.ec.KeyPair {
    const privateKey: string = typeof privateKeyOrKeyPair === 'string'
        ? privateKeyOrKeyPair
        : privateKeyOrKeyPair.privateKey;
    return starkEc.keyFromPrivate(normalizeHex32(privateKey));
}
