import { message } from "antd";
import { useCallback, useState } from "react";
import { WithdrawRequest } from "../../constants/type";

export const WithdrawalTransitionOperator = <R>(
  operator: (params: WithdrawRequest) => Promise<R>,
) => {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const operation = {
    loading,
    completed,
  };

  const signAndOperate = useCallback(
    async (params: WithdrawRequest) => {
      setLoading(true);
      try {
        const res = await operator(params);
        // const transaction = await onChainWithdraw(params);
        // console.log("transaction:", transaction);
        setLoading(false);
        setCompleted(true);
        return res;
      } catch (e) {
        setLoading(false);
        console.error(e);
        if (e instanceof Error) {
          message.error(e.message);
        }
      }
      return undefined;
    },
    [operator],
  );

  const resetOperationState = useCallback(() => {
    setLoading(false);
    setCompleted(false);
  }, []);

  return { operation, signAndOperate, resetOperationState };
};
