import { get, post } from "../helpers/request";
import { components } from "./api";

export const getStrategies = async (tokenId: number, address: string) => {
  const res = await get<components["schemas"]["Strategies"]>(`/assets/${tokenId}/strategies?user=${address}`);
  return res.strategies;
};

export const strategyOperation = async (params: components["schemas"]["StrategyOperationParams"]) => {
  return post<undefined>("/strategyOperation", params);
};
