export enum ScreenWidth {
  XS = 375,
  S = 1200,
  M = 1200,
  L = 1366,
  XL = 1440,
}

export const Media = {
  XS: `@media (max-width: ${ScreenWidth.S}px)`,
  S: `@media (max-width: ${ScreenWidth.M}px)`,
  M: `@media (max-width: ${ScreenWidth.L}px)`,
  L: `@media (max-width: ${ScreenWidth.XL}px)`,
};
