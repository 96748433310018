import { useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { UserRideHistory } from "../../api/history";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { getUserRideHistoryThunk } from "../../redux/investmentHistorySlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Theme } from "../../theme/theme";
import { ClockCustom } from "../customIcons";
import CustomTable from "../CustomTable";
import Loading from "../Loading";
import { getAssets, getWithdrawAssets } from "../../api/asset";
import { components } from "../../api/api";
import { fetchAllRiders } from "../../redux/RiderSlice";
import { Rider } from "../../api/rides";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    // margin: theme.hismainMargin,
  },
  mainText: {
    fontSize: theme.fontSizeL,
  },
  subText: {
    color: theme.fontColorSecondary,
    fontSize: theme.fontSizeS,
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mainchainHistory: {
    position: "relative",
  },
  historyList: {},
  historyListItem: {
    borderBottom: theme.border,
  },
  itemOne: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 18,
    "&:last-child": {
      paddingBottom: 20,
    },
  },
  ListItemLeft: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ListItemRight: {
    textAlign: "right",
    fontSize: "16px",
    color: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  itemTopname: {
    fontSize: "14px",
  },
  itemTopdesp: {
    fontSize: "14px",
    color: "#fff",
    lineHeight: 1,
  },
  itemTopval: {
    fontSize: "14px",
    color: "#00D395",
    marginLeft: 8,
    lineHeight: 1,
  },
  itemBottom: {
    fontSize: "12px",
    color: "#c4c4c4",
    marginTop: 4,
    lineHeight: 1,
  },
  infoText: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    justifyContent: "flex-end",
  },
}));

export default function StrategyHistory(): JSX.Element {
  const classes = useStyles();
  const { address } = useWeb3Context();
  const { histories, hasMore, loading, currentPage } = useAppSelector(state => state.investmentHistory);
  const dispatch = useAppDispatch();
  const starkKey = useWeb3Context().starkKey ?? "";
  const [withdrawAssets, setWithdrawAssets] = useState<components["schemas"]["Asset"][]>([]);
  const [assets, setAssets] = useState<components["schemas"]["Asset"][]>([]);
  const { ridersList } = useAppSelector(state => state);
  const { riders } = ridersList;
  const outputTokenIDs = Array.from(new Set(riders.map(it => it.output_token_id)));
  const selectedRiders = outputTokenIDs
    .map(outputTokenID => {
      return riders
        .filter(rider => rider.output_token_id === outputTokenID)
        .reduce((p: Rider, v: Rider) => {
          return (p.ride_id ?? 0) > (v.ride_id ?? 0) ? p : v;
        }, {});
    })
    .filter(it => (it.ride_id ?? 0) > 0)
    .sort((p, v) => (v.ride_id ?? 0) - (p.ride_id ?? 0));

  const fetchPage = useCallback(
    (pageNumber: number) => {
      if (!address) {
        return;
      }
      const pageSize = 100;
      dispatch(getUserRideHistoryThunk({ starkKey, pageNumber, pageSize }));
      // dispatch(fetchInvestmentHistory({ address, page }));
    },
    [address, starkKey, dispatch],
  );

  // on load init histories
  useEffect(() => {
    fetchPage(0);
  }, [fetchPage]);

  useEffect(() => {
    dispatch(fetchAllRiders());
  }, [dispatch]);

  useEffect(() => {
    if (!starkKey) {
      return;
    }
    (async () => {
      const result = await getWithdrawAssets(address);
      setWithdrawAssets(result);
    })();
    (async () => {
      const result = await getAssets(address);
      setAssets(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, starkKey]);

  const columns: ColumnsType<UserRideHistory> = useMemo(
    () => [
      {
        title: "Strategy",
        dataIndex: "ride_id",
        key: "ride_id",
        render: (_, history) => {
          const inputTokenID = history.input_token_id;
          const outputTokenID = history.output_token_id;
          const allAssets = [...assets, ...withdrawAssets];
          let inputTokenName = allAssets.find(it => it.tokenId === inputTokenID)?.name ?? "Unknown";
          let outputTokenName = allAssets.find(it => it.tokenId === outputTokenID)?.name ?? "Unknown";
          if (inputTokenName === "WETH") {
            inputTokenName = "ETH";
          }
          if (outputTokenName === "WETH") {
            outputTokenName = "ETH";
          }
          let strategyName = "Unknown";
          if (assets.find(it => it.tokenId === inputTokenID)) {
            strategyName = "Compound St";
          } else if (withdrawAssets.find(it => it.tokenId === inputTokenID)) {
            const ride = selectedRiders.find(
              it => it.input_token_id === history.output_token_id && it.output_token_id === history.input_token_id,
            );
            if (ride !== undefined) {
              strategyName = "Compound St";
            }
          }
          return <Typography>{`${strategyName} (${inputTokenName} -> ${outputTokenName})`}</Typography>;
        },
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: price => <Typography>{price}</Typography>,
      },
      {
        title: "Status",
        dataIndex: "ride_status",
        key: "ride_status",
        render: (_, history) => {
          const inputTokenID = history.input_token_id;
          const outputTokenID = history.output_token_id;
          let status = "Unknown";
          if (history.ride_status === "OFFBOARDING_DONE") {
            if (assets.map(it => it.tokenId).findIndex(it => it === inputTokenID) >= 0) {
              status = "Deposited";
            } else if (assets.map(it => it.tokenId).findIndex(it => it === outputTokenID) >= 0) {
              status = "Withdrawn";
            } else {
              status = "Unknown";
            }
          }
          if (history.ride_status !== "OFFBOARDING_DONE") {
            if (assets.map(it => it.tokenId).findIndex(it => it === inputTokenID) >= 0) {
              status = "Depositing";
            } else if (assets.map(it => it.tokenId).findIndex(it => it === outputTokenID) >= 0) {
              status = "Withdrawing";
            } else {
              status = "Unknown";
            }
          }
          return <Typography>{`${status} (${history.ride_status})`}</Typography>;
        },
      },
    ],
    [assets, selectedRiders, withdrawAssets],
  );

  return (
    <div className={classes.container}>
      <Loading
        loading={loading}
        emptyIcon={<ClockCustom />}
        emptyDescription="No L2 transaction history yet"
        isEmpty={!histories?.length}
      >
        <CustomTable
          dataSource={histories}
          columns={columns}
          loading={loading}
          currentPage={currentPage}
          hasMore={hasMore}
          onPageChange={(toPage: number) => fetchPage(toPage)}
        />
      </Loading>
    </div>
  );
}
