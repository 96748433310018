export const colorPallets = {
  successColor: "#68DE31",
  win: "#00D395",
  lose: "#fc5656",
  warn: "#FF8F00",

  fontColorPrimary: "#ffffff",
  fontColorSecondary: "#C4C4C4",
  fontColorTertiary: "#808080",
  fontColorAccented: "#CA9979",

  inverseFontColorPrimary: "#000000",
  inverseFontColorSecondary: "#808080",

  bgColorPrimary: "#0A0A0A",
  bgColorHighlightOnPrimary: "#4E4E4E",
  bgColorSecondary: "#222222",
  bgColorTertiary: "#3A3A3A",
  bgColorSubtle: "#2F2F2F",
  bgColorWin: "#1C4439",

  borderColorPrimary: "#808080",
};
