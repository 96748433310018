// import { get } from "../helpers/request";
import { components } from "./api";

export const getAssets = async (address: string): Promise<components["schemas"]["Asset"][]> => {
  // const res = await get<components["schemas"]["Assets"]>(`/assets?user=${address}`);
  // return res.assets;
  console.log(address);
  const assetsJson = `
  [
    {
      "id": "2",
      "address": "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
      "name": "WETH",
      "symbol": "WETH",
      "iconUrl": "https://cdn.jsdelivr.net/gh/trustwallet/assets/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
      "amount": "300000000000000000",
      "decimal": 18,
      "minWithdraw": "10000000000000000",
      "tvl": "102953379496215797290778",
      "tokenId": "0xb333e3142fe16b78628f19bb15afddaef437e72d6d7f5c6c20c6801a27fba6",
      "price": 1
    },
    {
      "id": "3",
      "address": "0xd87ba7a50b2e7e660f678a895e4b72e7cb4ccd9c",
      "name": "USDC",
      "symbol": "USDC",
      "iconUrl": "https://cdn.jsdelivr.net/gh/trustwallet/assets/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
      "amount": "300000000000000000",
      "decimal": 6,
      "minWithdraw": "10000000000000000",
      "tvl": "102953379496215797290778",
      "tokenId": "0xfc01e847a12978f384f58f4cca4c220f6cf7fed4782ec6086af043938027cb",
      "price": 1
    },
    {
      "id": "4",
      "address": "0xA380C20516AcfdBe9BD4151931e662f7958010E3",
      "name": "USDT",
      "symbol": "USDT",
      "iconUrl": "https://cdn.jsdelivr.net/gh/trustwallet/assets/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
      "amount": "300000000000000000",
      "decimal": 6,
      "minWithdraw": "10000000000000000",
      "tvl": "102953379496215797290778",
      "tokenId": "0x36eac8af77a5e759a542d8e4be0d936110bd2be697c96aa329d2784710e8050",
      "price": 1
    }
  ]
  `;

  const mainnetAssetJson = `
  [
    {
      "id": "1",
      "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
      "name": "USDT",
      "symbol": "USDT",
      "iconUrl": "https://get.celer.app/cbridge-icons/USDT.png",
      "amount": "300000000000000000",
      "decimal": 6,
      "minWithdraw": "0",
      "tvl": "102953379496215797290778",
      "tokenId": "0x2ce625e94458d39dd0bf3b45a843544dd4a14b8169045a3a3d15aa564b936c5",
      "price": 1
    },
    {
      "id": "2",
      "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      "name": "USDC",
      "symbol": "USDC",
      "iconUrl": "https://get.celer.app/cbridge-icons/USDC.png",
      "amount": "300000000000000000",
      "decimal": 6,
      "minWithdraw": "0",
      "tvl": "102953379496215797290778",
      "tokenId": "0x2893294412a4c8f915f75892b395ebbf6859ec246ec365c3b1f56f47c3a0a5d",
      "price": 1
    },
    {
      "id": "3",
      "address": "0xA380C20516AcfdBe9BD4151931e662f7958010E3",
      "name": "ETH",
      "symbol": "ETH",
      "iconUrl": "https://get.celer.app/cbridge-icons/ETH.png",
      "amount": "300000000000000000",
      "decimal": 18,
      "minWithdraw": "0",
      "tvl": "102953379496215797290778",
      "tokenId": "0xb333e3142fe16b78628f19bb15afddaef437e72d6d7f5c6c20c6801a27fba6",
      "price": 1
    }
  ]
  `;

  if (Number(process.env.REACT_APP_NETWORK_ID) === 1) {
    return JSON.parse(mainnetAssetJson);
  } 
  return JSON.parse(assetsJson);
};

export const getWithdrawAssets = async (address: string): Promise<components["schemas"]["Asset"][]> => {
  console.log(address);
  const assetsJson = `
    [
      {
        "id": "101",
        "address": "0xfa7E7903162862785fF0521C33772DDA94f3f4Bb",
        "name": "WcETH",
        "symbol": "WcETH",
        "iconUrl": "https://cdn.jsdelivr.net/gh/trustwallet/assets/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
        "amount": "300000000000000000",
        "decimal": 8,
        "minWithdraw": "10000000000000000",
        "tvl": "102953379496215797290778",
        "tokenId": "0x277f2ed8b0e5ac485f325f5941160231364ee76b05c89d9a728e596d0507836",
        "price": 1
      },
      {
        "id": "102",
        "address": "0x09FBf30B0844F36F4A1B2f9790bd569B2d5374ec",
        "name": "WcETH",
        "symbol": "WcETH",
        "iconUrl": "https://cdn.jsdelivr.net/gh/trustwallet/assets/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
        "amount": "300000000000000000",
        "decimal": 8,
        "minWithdraw": "10000000000000000",
        "tvl": "102953379496215797290778",
        "tokenId": "0x13c4ef6dd3afc38b8687e1eb75bc5125f8f124b6d209ca4ca15eeafc2455fd2",
        "price": 1
      },
      {
        "id": "103",
        "address": "0x487110377A1e159a923b1a072e10e78BF6d508F4",
        "name": "WcUSDC",
        "symbol": "WcUSDC",
        "iconUrl": "https://cdn.jsdelivr.net/gh/trustwallet/assets/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
        "amount": "300000000000000000",
        "decimal": 8,
        "minWithdraw": "10000000000000000",
        "tvl": "102953379496215797290778",
        "tokenId": "0x2d8d6d56bd6b74a0c550dc7fc1a6bd171e31bb8898b1503293bf34d6c668b6a",
        "price": 1
      },
      {
        "id": "104",
        "address": "0x4a4B27Db001080eE20F553696313913432137eF8",
        "name": "celrUSDT",
        "symbol": "celrUSDT",
        "iconUrl": "https://cdn.jsdelivr.net/gh/trustwallet/assets/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
        "amount": "300000000000000000",
        "decimal": 6,
        "minWithdraw": "10000000000000000",
        "tvl": "102953379496215797290778",
        "tokenId": "0x11f1c62ff8b83872885facfe694c24468e420a20c5f1de33a4212a2b8630508",
        "price": 1
      }
    ]
  `;
  const mainnetAssetsJson = `
  [
    {
      "id": "101",
      "address": "0x4ad37fFef83e3D26DdDc97afAf94e557CC389135",
      "name": "WcUSDT",
      "symbol": "WcUSDT",
      "iconUrl": "https://get.celer.app/cbridge-icons/USDT.png",
      "amount": "300000000000000000",
      "decimal": 8,
      "minWithdraw": "0",
      "tvl": "102953379496215797290778",
      "tokenId": "0x282b63a2ee157a22e2a702648bd728aba90113b43230369bd938fb058655a2f",
      "price": 1
    },
    {
      "id": "102",
      "address": "0x3F3b821243E5664822c0babBA2B4f37bf294e7a0",
      "name": "WcUSDC",
      "symbol": "WcUSDC",
      "iconUrl": "https://get.celer.app/cbridge-icons/USDC.png",
      "amount": "300000000000000000",
      "decimal": 8,
      "minWithdraw": "0",
      "tvl": "102953379496215797290778",
      "tokenId": "0x2e12f6467c27ab0c74ee4bd6533cc6688fda0c4b4c2fa8c98e775ebe1d11a77",
      "price": 1
    },
    {
      "id": "103",
      "address": "0x8581cd55ff53F1F85A237fa9D60E72a79f0973b6",
      "name": "WcETH",
      "symbol": "WcETH",
      "iconUrl": "https://get.celer.app/cbridge-icons/ETH.png",
      "amount": "300000000000000000",
      "decimal": 8,
      "minWithdraw": "0",
      "tvl": "102953379496215797290778",
      "tokenId": "0x34ed5bbc334c51be6c90f7d4b1dc682c016899de2ad46469694ece8dc11eeab",
      "price": 1
    }
  ]`;
  if (Number(process.env.REACT_APP_NETWORK_ID) === 1) {
    return JSON.parse(mainnetAssetsJson)
  } 
  return JSON.parse(assetsJson)
};
