import { Modal } from "antd";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  modalContent: {
    padding: "0px 10px",
    width: "100%",
  },
  buttonText: {
    marginTop: "80px",
    marginLeft: "20px",
    marginRight: "20px",
    width: "100%",
    height: "40px",
  },
  OpGuideModal: {
    "& .ant-modal-body": {
      minHeight: 100
    }
  }
});


function OpGuideModal({visible, onCancel}) {
  const styles = useStyles();
  console.log("visible", visible)
  return (
    <Modal mask okType="primary" visible={visible} title="Warning" style={{minHeight: 100}} className={styles.OpGuideModal} onCancel={onCancel} onOk={onCancel}>
      <div className={styles.modalContent}>Layer2.finance&apos;s zk version has wound down, please continue to our <a href="https://app.l2.finance/" target="_blank" rel="noreferrer">optimistic rollup version</a>.</div>
    </Modal>
  );
}

export default OpGuideModal;
