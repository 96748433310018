import { CloseOutlined, GithubFilled, MenuOutlined, TwitterCircleFilled } from "@ant-design/icons";
import { Button, Dropdown, Menu, PageHeader, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useMemo } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useToggle } from "react-use";
import { getNetworkById, NETWORKS } from "../constants/network";
import { Strata } from "../constants/zIndex";
import { DiscordCircleFilled, TelegramCircleFilled } from "../icons";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { useAppSelector } from "../redux/store";
import { Theme, ThemeType } from "../theme/theme";
import Account from "./Account";
import PageFooter from "./Footer";
import { FaucetModal } from "./modals";

const useStyles = createUseStyles((theme: Theme) => ({
  header: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
  },
  extra: {
    position: "absolute",
    right: 15,
    top: 15,
  },
  link: {
    color: "#ffffff",
  },
  mobileheader: {
    padding: 0,
  },
  headlogo: {
    marginTop: 20,
  },
  headerTop: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  cover: {
    width: "100vw",
    height: "100%",
    background: theme.bgColorPrimary,
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: Strata.FULLSCREEN,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  coverContent: {
    marginTop: 100,
    flex: 1,
  },
  coverContentItem: {
    marginBottom: 24,
    textAlign: "center",
  },
  followus: {
    marginTop: 20,
    textAlign: "center",
  },
  social: {
    textAlign: "center",
    fontSize: 24,
    "& span": {
      marginRight: 20,
    },
    marginTop: 18,
    display: "flex",
    justifyContent: "around",
  },
  closeIcon: {
    fontSize: 20,
    color: "#fff",
    position: "absolute",
    top: 30,
    right: 20,
  },
}));

export default function Header(): JSX.Element {
  const classes = useStyles();
  const { chainId } = useWeb3Context();
  const [showFaucet, toggleFaucet] = useToggle(false);
  const [menuModal, toggleMenu] = useToggle(false);
  const { assets } = useAppSelector(state => state.asset);
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;

  const tokenInfo = useMemo(() => {
    return assets
      .filter(token => token.symbol !== "ETH")
      .map(token => ({ symbol: token.symbol || "", address: token.address || "" }));
  }, [assets]);

  const expectedChainId = Number(process.env.REACT_APP_NETWORK_ID);

  if (chainId && chainId !== expectedChainId) {
    const expectedNetwork = getNetworkById(expectedChainId);
    const name = expectedNetwork.name;
    return (
      <Modal closable={false} visible width={360} footer={null}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography.Text className="sub-typography">
            Please switch to {name.charAt(0).toUpperCase() + name.slice(1)}
          </Typography.Text>
        </div>
      </Modal>
    );
  }

  const handleToggleFaucet = () => {
    toggleMenu(false);
    toggleFaucet();
  };

  const logoUrl = "./starkware-logo.png";

  return (
    <div>
      {!isMobile ? (
        <div className={classes.header}>
          <PageHeader title={<img src={logoUrl} height="35px" alt="LAYER2.FINANCE" />} />
          <div className={classes.extra}>
            {chainId === 1 && (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item 
                      key="en_US"
                      onClick={() => window.open("https://celer-network.gitbook.io/layer.2-finance-zk-proofs-model-tutorial", "_blank")}
                    >
                      English
                    </Menu.Item>
                    <Menu.Item
                      key="zh_CN"
                      onClick={() => window.open("https://celer-network.gitbook.io/layer.2-finance-zk-proofs-model-tutorial/zhong-wen-jiao-cheng", "_blank")}
                    >
                      中文
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className={classes.link} type="text" size="large">
                  Guide
                </Button>
              </Dropdown>
            )}
            {chainId === 1 && (
              <Button
                className={classes.link}
                type="text"
                size="large"
                onClick={() => window.open("https://docs.l2.finance/#/faq", "_blank")}
              >
                FAQ
              </Button>
            )}
            {expectedChainId !== NETWORKS.mainnet.chainId && (
              <Button className={classes.link} type="text" size="large" onClick={toggleFaucet}>
                Faucets
              </Button>
            )}
            <Account />
          </div>
        </div>
      ) : (
        <div className={classes.mobileheader}>
          <div className={classes.headerTop}>
            <div className={classes.headlogo}>
              <img src={logoUrl} alt="LAYER2.FINANCE" style={{ width: 150 }} />
            </div>
            <div style={{ display: "flex", alignItems: "center", marginTop: 14 }}>
              <Account />
              <MenuOutlined style={{ fontSize: "20px", marginLeft: 10 }} onClick={toggleMenu} />
            </div>
          </div>
          {menuModal && (
            <div className={classes.cover}>
              <CloseOutlined className={classes.closeIcon} onClick={toggleMenu} />
              <div className={classes.coverContent}>
                {chainId === 1 && (
                  <div className={classes.coverContentItem}>
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            key="en_US"
                            onClick={() => window.open("https://docs.l2.finance/#/tutorial-en", "_blank")}
                          >
                            English
                          </Menu.Item>
                          <Menu.Item
                            key="zh_CN"
                            onClick={() => window.open("https://docs.l2.finance/#/tutorial-cn", "_blank")}
                          >
                            中文
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button className={classes.link} type="text" size="large">
                        Guide
                      </Button>
                    </Dropdown>
                  </div>
                )}
                {chainId === 1 && (
                  <div className={classes.coverContentItem}>
                    <Button
                      className={classes.link}
                      type="text"
                      size="large"
                      onClick={() => window.open("https://docs.l2.finance/#/faq", "_blank")}
                    >
                      FAQ
                    </Button>
                  </div>
                )}
                {expectedChainId !== NETWORKS.mainnet.chainId && (
                  <div className={classes.coverContentItem}>
                    <Button className={classes.link} type="text" size="large" onClick={handleToggleFaucet}>
                      Faucets
                    </Button>
                  </div>
                )}
                <div className={classes.followus}>
                  <Button className={classes.link} type="text" size="large">
                    Follow Us
                  </Button>
                  <div className={classes.social}>
                    <DiscordCircleFilled onClick={() => window.open("https://discord.gg/uGx4fjQ", "_blank")} />
                    <TelegramCircleFilled onClick={() => window.open("https://t.me/celernetwork", "_blank")} />
                    <TwitterCircleFilled onClick={() => window.open("https://twitter.com/CelerNetwork", "_blank")} />
                    <GithubFilled
                      style={{ marginRight: 0 }}
                      onClick={() =>
                        window.open("https://github.com/celer-network/layer2-finance-v2-contracts", "_blank")
                      }
                    />
                  </div>
                </div>
              </div>
              <PageFooter />
            </div>
          )}
        </div>
      )}
      {showFaucet && <FaucetModal tokenInfos={tokenInfo} onClose={toggleFaucet} />}
    </div>
  );
}
