import { Button, Typography } from "antd";
import _ from "lodash";
import { BigNumber } from "ethers";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { components } from "../../api/api";
import { getAssets } from "../../api/asset";
import { DepositWithdrawHistoryEntity } from "../../api/history";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { Strata } from "../../constants/zIndex";
import { formatDecimal } from "../../helpers/format";
import { ETHTokenID } from "../../helpers/riderUtils";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { fetchMainchainHistory } from "../../redux/mainchainHistorySlice";
import { removeDepositTx } from "../../redux/persistedTxSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Theme, ThemeType } from "../../theme/theme";
import { ClockCustom } from "../customIcons";
import CustomTable from "../CustomTable";
import LabelWithPopover from "../LabelWithPopover";
import Loading from "../Loading";
import { ActionModal } from "../modals";
import ActionTitle from "../modals/common/ActionTitle";
import PageFlipper from "../PageFlipper";
import TokenGain from "../TokenGain";


const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    margin: theme.hismainMargin,
  },
  refreshButton: {
    position: "absolute",
    background: theme.bgColorPrimary,
    right: 10,
    top: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    "& span": {
      color: ["#c4c4c4", "!important"],
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
  subDescription: {
    fontSize: theme.fontSizeS,
  },
  mainchainHistory: {
    width: "100%",
    position: "relative",
  },
  historyList: {},
  historyListItem: {
    borderBottom: theme.border,
    padding: "24px 0",
  },
  historyListItemRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "&:not(:last-child)": {
      marginBottom: 14,
    },
  },
  ListItemLeft: {
    textAlign: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  ListItemRight: {
    textAlign: "right",
    fontSize: "16px",
    color: "#fff",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  itemTop: {
    lineHeight: 1,
  },
  itemTopdesp: {
    fontSize: "14px",
    color: "#fff",
    lineHeight: 1,
  },
  itemTopval: {
    fontSize: "14px",
    color: "#00D395",
    marginLeft: 8,
    lineHeight: 1,
  },
  itemBottom: {
    fontSize: "12px",
    color: "#c4c4c4",
    marginTop: 4,
    lineHeight: 1,
  },
  historyInfo: {
    color: theme.fontColorSecondary,
    fontSize: theme.fontSizeM,
  },
  reloadbtn: {
    position: "absolute",
    top: -125,
    right: 0,
    zIndex: Strata.LOW,
  },
}));

export default function MainchainHistory(): JSX.Element {
  const classes = useStyles();

  // blockchain states
  const { address, starkKey, provider } = useWeb3Context();

  // redux
  const { persistedTx, mainchainHistory } = useAppSelector(state => state);
  const { withdrawConfirmationTimes, depositTxs } = persistedTx;
  const { entries, hasMore, loading } = mainchainHistory;
  const dispatch = useAppDispatch();

  // local states
  const [selectedRecord, setSelectedRecord] = useState<L1History>();
  const [errMsg] = useState();
  const [confirmLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;
  const [assets, setAssets] = useState<components["schemas"]["Asset"][]>([]);
  const { starkContract, transactor } = useContractsContext();

  const withdrawFormOnChain = async tokenId => {
    if (starkContract && transactor) {
      await transactor(starkContract.withdraw(address ?? "", tokenId));
    }
  };

  interface L1History {
    transactionType?: "DEPOSIT" | "WITHDRAWAL";
    vaultId?: number;
    tokenId?: string;
    amount?: number;
    status?: "PENDING" | "SENT" | "COMPLETED" | "INVALID";
    withdrawBalance: BigNumber;
  }

  const [l1HistoryList, setL1HistoryList] = useState<L1History[]>([])

  useEffect(() => {
    if (!starkKey) {
      return;
    }
    (async () => {
      const result = await getAssets(address);
      setAssets(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, starkKey]);

  // For each locally persisted pending deposits that don't have 5 block confirmations yet,
  // wait for 6 confirmations to happen then remove locally persisted tx.
  // The reason of adding one extra confirmation here is to get around the issue that by
  // the time frontend observes 5 confirmations, backend may not, so that the pending deposit
  // could disappear for a moment
  useEffect(() => {
    if (!provider || currentPage !== 0 || !depositTxs) {
      return;
    }
    Object.values(depositTxs).forEach(tx => {
      provider.waitForTransaction(tx.hash, 8).then(_tx => {
        console.log(`saw the 8th confirmation of tx ${tx.hash}, removing it from cache`);
        dispatch(removeDepositTx(tx.hash));
        dispatch(fetchMainchainHistory({ starkKey, page: 0 }));
      });
    });
  }, [currentPage, depositTxs, dispatch, provider, starkKey]);

  useEffect(() => {
    if (!address) {
      return;
    }
    dispatch(fetchMainchainHistory({ starkKey, page: currentPage }));
  }, [address, currentPage, dispatch, starkKey]);


  useEffect(() => {
    const getWithdrawalBalance = async(item: DepositWithdrawHistoryEntity) => {
      return starkContract?.getWithdrawalBalance(address, BigNumber.from(item.token_id))
    }
    // eslint-disable-next-line
    const promiseList: Promise<any>[] = []
    const resultList: L1History[] = [];
    entries.forEach(item => {
      if (item.token_id && item.transaction_type === "WITHDRAWAL" && item.status === "SENT") {
        promiseList.push(getWithdrawalBalance(item))
      } else {
        promiseList.push(Promise.resolve(0))
      }
    });

    if(promiseList && promiseList.length > 0){
      Promise.all(promiseList).then(it => {
        it.forEach((balance, index) => {
          const item = entries[index]
          resultList.push({
            transactionType: item.transaction_type,
            vaultId: item.vault_id,
            tokenId: item.token_id,
            amount: item.amount,
            status: item.status,
            withdrawBalance: balance,
          });
        }) 
        setL1HistoryList(resultList)
      })
    }
  }, [entries, address, starkContract]);

  const getTokenName = useCallback(
    (tokenID: string | undefined) => {
      const tokenName = assets.find(it => it.tokenId === tokenID)?.symbol;
      if (tokenName === "WETH") {
        return "ETH";
      }
      return tokenName;
    },
    [assets],
  );

  const mapDataToCols = useCallback(
    (entry: L1History, index: number) => {
      let time: number | undefined;
      return {
        key: `${entry.tokenId}-${index}`,
        tokenId: entry.tokenId,
        amount: {
          action: entry.transactionType,
          assetAmt: formatDecimal(
            entry.amount,
            entry.tokenId === ETHTokenID ? 8 : assets.find(it => it.tokenId === entry.tokenId)?.decimal,
          ),
          assetSymbol: getTokenName(entry.tokenId),
        },
        fee: {
          feeAmt: formatDecimal(0, 0),
          feeToken: getTokenName(entry.tokenId),
        },
        action: entry.transactionType,
        time,
        status: entry.status,
        withdrawalBalance: entry.withdrawBalance,
      };
    },
    [assets, getTokenName],
  );
  const changeStatus = record => {
    if (!address) {
      return null;
    }
    if (record.status === "COMPELTE" && record.action === "WITHDRAWAL") {
      const wct = withdrawConfirmationTimes[address];
      if (wct) {
        const lastWithdrawConfirmationTime =
          record.status?.assetSymbol && withdrawConfirmationTimes[address][record.status?.assetSymbol];
        const executeTimestamp = parseInt(record.status.executeTimestamp || "0", 10);
        if (lastWithdrawConfirmationTime && lastWithdrawConfirmationTime >= executeTimestamp) {
          return (
            <LabelWithPopover label="Confirming" placement="topRight">
              Your withdrawal is being confirmed on L1. Please allow a few minutes before the funds are credited to your
              L1 wallet.
            </LabelWithPopover>
          );
        }
      }
      return (
        <Button
          type="primary"
          onClick={() => {
            setConfirmed(false);
            setSelectedRecord(record.status);
          }}
        >
          Confirm
        </Button>
      );
    }

    switch (record.status) {
      case "PENDING":
        return record.action === "DEPOSIT" ? (
          <LabelWithPopover label="Confirming" placement="topRight">
            Your deposit is being confirmed on-chain. Please allow 5 block confirmations (3-5 minutes) for your L2
            balance to be credited.
          </LabelWithPopover>
        ) : (
          <LabelWithPopover label="Pending">
            <p>
              Your fund is being transferred from L2 to L1, which might take {process.env.REACT_APP_WITHDRAW_WINDOW} to
              complete.
            </p>
            <p>
              NOTE: In order to receive the fund in your L1 wallet, you will need to
              <span style={{ fontWeight: "bold" }}> manually confirm</span> the withdrawal again{" "}
              {process.env.REACT_APP_WITHDRAW_WINDOW} later on this page.
            </p>
          </LabelWithPopover>
        );
      case "COMPLETED":
        return "Completed";
      case "SENT":
        return "Success";
      default:
        return "Failed";
    }
  };

  const columns = useMemo(
    () => [
      {
        title: <span>Transaction</span>,
        dataIndex: "amount",
        render: (e, record) => {
          const sign = record.amount.action === "DEPOSIT" ? "" : "-";
          return <TokenGain formattedAmount={sign + record.amount.assetAmt} symbol={record.amount.assetSymbol} />;
        },
      },
      {
        title: <span>Action</span>,
        dataIndex: "action",
        render: (e, record) => {
          switch (record.action) {
            case "DEPOSIT":
              return "Deposit to L2";
            case "WITHDRAWAL":
              return "Withdraw to L1";
            default:
              return "Unknown";
          }
        },
      },
      {
        title: <span>Status</span>,
        dataIndex: "status",
        render: (e, record) => changeStatus(record),
      },

      {
        title: "",
        detaIndex: "withdrawToWallet",
        render: (e, record) => {
          if (record.withdrawalBalance && record.withdrawalBalance.gt(0)) {
            return (
              <Button
                type="primary"
                onClick={() => {
                  console.log("setSelectedRecord",record)
                  setSelectedRecord(record);
                  setConfirmed(true)
                }}
                disabled
              >
                Withdraw
              </Button>
            );
          }
          return "";
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [address, classes.subDescription, withdrawConfirmationTimes, changeStatus],
  );

  let content: ReactNode;
  if (confirmed) {
  //   content = (
  //     <div className={classes.content}>
  //       <Typography.Text>
  //         Your withdrawal is being confirmed on L1. Please allow a few minutes before the funds are credited to your L1
  //         wallet.
  //       </Typography.Text>
  //     </div>
  //   );
  //   actionText = "Ok";
  //   action = () => {
  //     setConfirmed(false);
  //     setSelectedRecord(undefined);
  //   };
  // } else {
    content = (
      <div className={classes.content}>
        <Typography.Text style={{ marginBottom: 12 }}>
          By clicking “Confirm”, all of your pending L1 withdrawals for {_.get(selectedRecord, "assetSymbol")} will be
          confirmed in this single transaction. No need to click confirm for other L1 withdrawals for{" "}
          {_.get(selectedRecord, "assetSymbol")}.
        </Typography.Text>
        <Typography.Text>
          The confirmation is an L1 transaction that costs some gas fee and it may take a few minutes to receive the
          withdrawn assets in your L1 wallet.
        </Typography.Text>
      </div>
    );
  }
  
  const mobileList = Object.values(l1HistoryList).map(entry => {
    let time: number | undefined;

    return {
      key: entry.tokenId,
      amount: {
        action: entry.transactionType,
        assetAmt: formatDecimal(entry.amount, 8),
        assetSymbol: getTokenName(entry.tokenId),
      },
      fee: {
        feeAmt: formatDecimal(0, 0),
        feeToken: getTokenName(entry.tokenId),
      },
      action: entry.transactionType,
      time,
      status: entry.status,
    };
  });
  return (
    <div className={classes.container}>
      {isMobile ? (
        <div className={classes.mainchainHistory}>
          <Loading
            loading={loading}
            emptyIcon={<ClockCustom height={20} width={20} />}
            emptyDescription="No L1 transaction history yet"
            isEmpty={!l1HistoryList.length}
          >
            <div className={classes.historyList}>
              {mobileList.map(item => {
                return (
                  <div className={classes.historyListItem} key={item.key}>
                    <div className={classes.historyListItemRow}>
                      <div className={classes.ListItemLeft}>
                        <div className={classes.itemTop}>
                          <span className={classes.itemTopdesp}>
                            {(() => {
                              switch (item.action) {
                                case "DEPOSIT":
                                  return "Deposit to L2";
                                case "WITHDRAWAL":
                                  return "Withdraw to L1";
                                default:
                                  return "Unknown";
                              }
                            })()}
                          </span>
                          <span className={classes.itemTopval}>
                            {(() => {
                              const sign = item.amount.action === "DEPOSIT" ? "" : "-";
                              return (
                                <TokenGain
                                  formattedAmount={sign + item.amount.assetAmt}
                                  symbol={item.amount.assetSymbol}
                                />
                              );
                            })()}
                          </span>
                        </div>
                        <div className={classes.itemBottom}>
                          {(() => {
                            if (!item.time) {
                              return "--";
                            }
                            const epoch = _.toNumber(item.time);
                            return new Date(epoch).toLocaleString();
                          })()}
                        </div>
                      </div>
                      <div className={classes.ListItemRight}>{changeStatus(item)}</div>
                    </div>
                    {item.action === "WITHDRAWAL" && (
                      <div className={classes.historyListItemRow}>
                        <div className={classes.ListItemLeft}>
                          <LabelWithPopover label="Fee">
                            There is a processing fee for each transaction in order to cover the operation cost of
                            Layer2.Finance.
                          </LabelWithPopover>
                        </div>
                        <div className={classes.ListItemRight}>
                          <span className={classes.historyInfo}>{`${item.fee.feeAmt} ${item.fee.feeToken}`}</span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={classes.pagination}>
              <PageFlipper
                page={currentPage}
                hasMore={hasMore}
                onPageChange={(toPage: number) => setCurrentPage(toPage)}
              />
            </div>
          </Loading>
        </div>
      ) : (
        <Loading
          loading={loading}
          emptyIcon={<ClockCustom />}
          emptyDescription="No L1 transaction history yet"
          isEmpty={!l1HistoryList.length}
        >
          <CustomTable
            dataSource={l1HistoryList.map(mapDataToCols)}
            columns={columns}
            loading={loading}
            currentPage={currentPage}
            hasMore={hasMore}
            onPageChange={(toPage: number) => setCurrentPage(toPage)}
          />
        </Loading>
      )}

      <ActionModal
        visible={!!selectedRecord}
        actionText="Confirm"
        title={<ActionTitle title="Withdraw to L1" />}
        actionLoading={confirmLoading}
        errMsg={errMsg}
        onCancel={() => setSelectedRecord(undefined)}
        onAction={async() => {
          await withdrawFormOnChain(selectedRecord?.tokenId).catch(e => {
            console.log("error",e)
            setSelectedRecord(undefined)
          });
          setSelectedRecord(undefined)
        }}
      >
        {content}
      </ActionModal>
    </div>
  );
}
