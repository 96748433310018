import { get } from "../helpers/request";
import { stripHexPrefix } from "../starkex/helpers";
import baseUrl from "./baseUrl";

export async function getVaultId(starkKey: string, assetId: string) {
  const res = await get<VaultID>(`/get_vault_id?stark_key=${starkKey}&token_id=${assetId}`, `${baseUrl}/defi_express`);
  return res;
}

/* eslint-disable */
export interface VaultID {
  vault_id?: number;
}

export async function getBalance(starkKey: string) {
  const key = stripHexPrefix(starkKey);
  if (starkKey.length === 0) {
    return [];
  }
  const res = await get<Balance[]>(`/get_balance?stark_key=${key}`, `${baseUrl}/defi_express`);
  return res;
}

/* eslint-disable */
export interface Balance {
  vault_id?: number;
  token_id?: string;
  balance?: number;
}
