import { colorPallets } from "./colorPallets";
import { ThemeBase, ThemeType } from "./theme";

const themeBase: ThemeBase = {
  type: ThemeType.S,

  fontSizeS: 10,
  fontSizeM: 12,
  fontSizeL: 14,
  fontSizeXL: 16,

  iconSizeL: 20,

  fontWeightLight: 200,
  fontWeightNormal: 400,
  fontWeightBold: 600,
  fontWeightBolder: 800,

  borderRadiusXL: 24,
  borderRadiusL: 12,
  borderRadiusM: 10,
  borderRadiusS: 8,

  cardPaddingXL: 10,
  cardPaddingL: 10,
  cardPaddingM: 8,
  cardPaddingS: 6,

  border: "1px solid #555",
  borderLight: "1px solid #2B2B2B",
  borderLighter: "1px solid #eee",

  highlightedText: {
    color: "#ffffff",
    fontWeight: "bold",
  },
  contentMaxWidth: 480,
  contentMinWidth: 320,
  xsFlex: "block",
  balanceUnit: {
    height: 50,
    borderTop: "1px solid #808080",
    lineHeight: "50px",
    marginRight: 0,
  },
  countmarginLeft: "0px",
  apypadding: "6px 8px",
  actflexDirection: "unset",
  invlexDirection: "space-between",
  actalignItems: "center",
  modalbackground: "#2f2e2e",
  contentBackground: "#1a1a1a",
  hismainMargin: "0",
  apyLine: 1,
  modalBottom: "5px",
};

const withColorPallets = { ...themeBase, ...colorPallets };

export default withColorPallets;
