import { get } from "../helpers/request";
import { components } from "./api";
import { stripHexPrefix } from "../starkex/helpers";
import baseUrl from "./baseUrl";

export const getInvestmentHistory = (address: string, page: number, limit = 20) => {
  return get(`/users/${address}/investmentHistory?page=${page}&limit=${limit}`) as Promise<
    components["schemas"]["InvestmentHistory"]
  >;
};

export const getFarmingHistory = (address: string, page: number, limit = 20) => {
  return get(`/users/${address}/farmingHistory?page=${page}&limit=${limit}`) as Promise<
    components["schemas"]["FarmingHistory"]
  >;
};

export const getStarkexMainchainHistory = (starkKey: string, page: number, limit = 100) => {
  const key = stripHexPrefix(starkKey);
  const res = get<DepositWithdrawHistoryEntity[]>(
    `/get_deposit_withdrawal_history?stark_key=${key}&page_size=${limit}&page_number=${page}`,
    `${baseUrl}/defi_express`,
  );
  return res;
};

export const getUserRideHistory = (starkKey: string, pageSize: number, pageNumber: number) => {
  return get<UserRideHistory[]>(
    `/get_user_ride_history?stark_key=${starkKey}&page_size=${pageSize}&page_number=${pageNumber}`,
    `${baseUrl}/defi_express`,
  );
};

/* eslint-disable */
export interface UserRideHistory {
  dp_tx_id?: number;
  ride_id?: number;
  input_token_id?: string;
  output_token_id?: string;
  price?: number;
  slippage?: number;
  fee?: number;
  ride_status?:
    | "PENDING"
    | "ONBOARDING_IN_PROGRESS"
    | "ONBOARDING_DONE"
    | "OFFBOARDING_IN_PROGRESS"
    | "OFFBOARDING_DONE"
    | "CANCELLATION_DONE";
}

/* eslint-disable */
export interface DepositWithdrawHistoryEntity {
  transaction_type?: "DEPOSIT" | "WITHDRAWAL";
  vault_id?: number;
  token_id?: string;
  amount?: number;
  status?: "PENDING" | "SENT" | "COMPLETED" | "INVALID";
  withdrawToWallet: boolean;
}
