import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Balance, getBalance } from "../api/vaultId";

interface L2BalanceState {
  balance: Balance[];
  loading: boolean;
}

const initialState: L2BalanceState = {
  balance: [],
  loading: false,
};

export const fetchBalance = createAsyncThunk("getBalance", async (starkKey: string) => {
  return getBalance(starkKey).catch(_ => null);
});

const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBalance.pending, state => {
        console.log("fetchBalance status:pending");
        state.loading = true;
      })
      .addCase(fetchBalance.rejected, state => {
        console.log("fetchBalance status:rejected");
        state.loading = false;
      })
      .addCase(fetchBalance.fulfilled, (state, { payload }) => {
        state.loading = false;

        if (payload) {
          state.balance = payload;
        }
      });
  },
});

export default balanceSlice.reducer;
