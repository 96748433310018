import { ButtonType } from "antd/lib/button";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { parseUnits, formatUnits } from "@ethersproject/units";
import { MaxButton } from "../..";
import { strategyOperation } from "../../../api/strategy";
import { formatDecimal, formatPercentage } from "../../../helpers/format";
import { gte, lte } from "../../../helpers/numbers";
import { hashStrategyRequest } from "../../../helpers/requestHashers";
import { getRedeemedAmountAfterSell } from "../../../helpers/strategy";
import { IValidator, useTokenInputState } from "../../../helpers/tokenAmountState";
import { useTransitionOperator } from "../../../hooks/operation/transitionOperator";
import { useWeb3Context } from "../../../providers/Web3ContextProvider";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { fetchStrategies } from "../../../redux/strategySlice";
import LabelWithPopover from "../../LabelWithPopover";
import { ITokenInputChangeEvent } from "../../TokenInput";
import ActionModal from "../common/ActionModal";
import ActionTitle from "../common/ActionTitle";
import ModalExtraInfoRow from "../common/ModalExtraInfoRow";
import ModalResult from "../common/ModalResult";
import ModalTokenInput from "../common/ModalTokenInput";
import { components } from "../../../api/api";
import { ETHTokenID } from "../../../helpers/riderUtils";

export interface JoinAmountModalProps {
  isJoin: boolean;
  isLoading: boolean;
  strategy: components["schemas"]["Strategy"];
  token: components["schemas"]["Asset"];
  onClickJoin: (amount: number) => void;
  onClose: () => void;
}

export default function JoinAmountModal({
  isJoin,
  isLoading,
  strategy,
  token,
  onClickJoin,
  onClose,
}: JoinAmountModalProps): JSX.Element {
  const { address } = useWeb3Context();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { l2balance } = useAppSelector(state => state);
  const minWithdraw = useMemo(() => formatDecimal(token.minWithdraw, token.decimal, token.decimal), [token]);
  const { balance } = l2balance;
  const availableAmount = balance.length ? balance[0].balance ?? 0 : 0;
  const bigBalanceAmount = parseUnits(availableAmount / 100000000 + "");
  const tokenAmount =
    Object.values(balance)
      .filter(it => (it.balance ?? 0) > 0)
      .find(it => token.tokenId.includes(it.token_id?.substring(2) ?? ""))?.balance ?? 0;
  const validators: IValidator[] = useMemo(
    () => [
      {
        validator: amtBig => gte(amtBig, token.minWithdraw),
        message: `Please input a number larger than ${minWithdraw}`,
      },
      {
        validator: amtBig => gte(amtBig, token.withdrawFee?.amount),
        message: "Please enter an amount larger than fee",
      },
      {
        validator: amtBig => lte(amtBig, bigBalanceAmount),
        message: "You don't have enough strategy token to withdraw",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minWithdraw, token],
  );
  const [amount, setAmount] = useTokenInputState(token.decimal, undefined, validators);

  const { operation, resetOperationState: reset } = useTransitionOperator(strategyOperation, hashStrategyRequest);

  const handleAction = async () => {
    onClickJoin(Number(amount.input));
  };

  const handleModalClose = useCallback(() => {
    if (operation.completed) {
      dispatch(fetchStrategies({ address, tokenId: token.id }));
      reset();
    }
    onClose();
  }, [operation.completed, onClose, dispatch, address, token.id, reset]);

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
  };

  const strategyNameRow = <ModalExtraInfoRow key={1} left="Strategy Name" right={strategy.name} />;
  const withdrawAmountRow = (
    <ModalExtraInfoRow key={2} left="Withdraw Amount" right={`${amount.formatted} ${strategy.shareName}`} />
  );
  const maxFeeRow = (
    <ModalExtraInfoRow
      key={3}
      left={
        <LabelWithPopover label="Max Fee">
          This the max processing fee charged for this transaction to cover the operation cost. Note that the actual fee
          may be smaller when this transaction is executed.
        </LabelWithPopover>
      }
      right={`${formatDecimal(strategy.maxWithdrawFee?.amount, strategy.maxWithdrawFee?.decimal)}
      ${strategy.maxWithdrawFee?.symbol}`}
    />
  );
  const youReceiveRow = (
    <ModalExtraInfoRow
      key={4}
      left={
        <LabelWithPopover label="You Receive">
          This is the approximate {token.symbol} amount you will receive after the max fee is deducted, estimated based
          on the current stToken price. The actual {token.symbol} amount you will receive may vary depending on the
          stToken price and the actual processing fee when this transaction is executed (up to $
          {process.env.REACT_APP_CONFIRM_WINDOW} later).
        </LabelWithPopover>
      }
      right={`≈ ${formatDecimal(getRedeemedAmountAfterSell(amount.big, strategy), token.decimal)} ${token.symbol}`}
    />
  );
  const slippageTolerance = (
    <ModalExtraInfoRow
      key={5}
      left={
        <LabelWithPopover label="Slippage Tolerance">
          Due to the fluctuation of strategy token price, the actual {token.symbol} amount you will receive might be
          smaller than the estimated amount you see above. If the relative difference is larger than{" "}
          {formatPercentage(strategy.withdrawSlippageTolerance)}, the transaction will be reverted.
        </LabelWithPopover>
      }
      right={formatPercentage(strategy.withdrawSlippageTolerance)}
    />
  );

  let content;
  let action: () => void;
  let actionText: string;
  let buttonType: ButtonType;
  let extra;

  const maxValue = formatUnits(tokenAmount || 0, token.tokenId === ETHTokenID ? 8 : token.decimal)
  if (operation.completed) {
    content = (
      <ModalResult
        title="Withdrawal has been submitted"
        description={
          <span>
            It may take up to {process.env.REACT_APP_CONFIRM_WINDOW} to finalize your withdrawal from the strategy. You
            can check the progress in the history page.
          </span>
        }
      />
    );
    action = () => history.push("/history/investment");
    actionText = "Check History";
    buttonType = "link";
    extra = [strategyNameRow, withdrawAmountRow, maxFeeRow, youReceiveRow, slippageTolerance];
  } else {
    content = (
      <ModalTokenInput
        description="Please enter the amount."
        amount={amount.input}
        maxButton={
          <LabelWithPopover
            placement="top"
            label={
              <div style={{ display: "inline-block" }}>
                <MaxButton
                  onClick={() => 
                    {
                      setAmount(maxValue)
                    }}
                  value={formatDecimal(tokenAmount || 0, token.tokenId === ETHTokenID ? 8 : token.decimal)}
                  symbol={token.symbol}
                />
              </div>
            }
          >
            Strategy Token (stToken) is your liquidity token that can be used to redeem the underlying asset along with
            yields back to your available L2 balance.
          </LabelWithPopover>
        }
        symbol={token.symbol}
        onChange={handleTokenInputChange}
      />
    );
    action = handleAction;
    actionText = isJoin ? "Join" : "Withdraw";
    buttonType = "primary";
    // extra = [strategyNameRow, maxFeeRow, youReceiveRow, slippageTolerance];
  }

  return (
    <ActionModal
      visible
      title={<ActionTitle title={isJoin ? "Join to Strategy" : "Withdraw from Strategy"} token={token} />}
      actionText={actionText}
      actionDisabled={!amount.input || Number(amount.input) === 0 || Number(amount.input) > Number(maxValue)}
      errMsg={amount.error}
      onCancel={handleModalClose}
      onAction={action}
      actionLoading={isLoading}
      buttonType={buttonType}
      extra={extra}
    >
      {content}
    </ActionModal>
  );
}
