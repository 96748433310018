import { MainchainHistory, StrategyHistory } from "../components";
import RouteTabbedCard, { IRoute } from "../components/RouteTabbedCard";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { fetchInvestmentHistory } from "../redux/investmentHistorySlice";
import { fetchMainchainHistory } from "../redux/mainchainHistorySlice";
import { useAppDispatch } from "../redux/store";

export default function History(): JSX.Element {
  const dispatch = useAppDispatch();
  const { starkKey, address } = useWeb3Context();

  const handleMainchainHistoryReload = () => {
    dispatch(fetchMainchainHistory({ starkKey, page: 0 }));
  };

  const handleStrategyHistoryReload = () => {
    dispatch(fetchInvestmentHistory({ address, page: 0 }));
  };

  const routes: IRoute[] = [
    {
      path: "/history/mainchain",
      title: "Layer1 Transactions",
      component: <MainchainHistory />,
      onReload: handleMainchainHistoryReload,
    },
    {
      path: "/history/investment",
      title: "Layer2 Transactions",
      component: <StrategyHistory />,
      onReload: handleStrategyHistoryReload,
    },
  ];

  return <RouteTabbedCard routes={routes} redirect={{ from: "/history", to: "/history/mainchain" }} />;
}
