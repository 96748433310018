import { notification } from "antd";
import Notify from "bnc-notify";
import { BigNumber, BigNumberish, ethers } from "ethers";

import { JsonRpcProvider, Provider, TransactionRequest, TransactionResponse } from "@ethersproject/providers";
import { parseUnits } from "@ethersproject/units";

export type Transactor<T extends ethers.Transaction> = (
  tx: Promise<T> | ethers.utils.Deferrable<TransactionRequest>,
) => Promise<T | TransactionResponse>;

/**
 * A wrapper around BlockNative's wonderful Notify.js https://docs.blocknative.com/notify
 *
 * @param provider The Ethereum Provider
 * @param gasPrice Optional gas price
 * @param etherscanUrl Optional Etherscan URL
 */
export default function transactorWithNotifier<T extends ethers.Transaction>(
  provider?: Provider | undefined,
  gasPrice?: BigNumberish | undefined,
  etherscanUrl?: string,
): Transactor<T> | undefined {

  if (typeof provider !== "undefined" && provider instanceof JsonRpcProvider) {
    return async (tx: Promise<T> | ethers.utils.Deferrable<TransactionRequest>): Promise<T | TransactionResponse> => {
      const signer = provider.getSigner();
      console.log("signer:" , signer)
      console.log("inject transactor: provier:", provider)
      const network = await provider.getNetwork();
      const options = {
        dappId: "0b58206a-f3c0-4701-a62f-73c7243e8c77", // GET YOUR OWN KEY AT https://account.blocknative.com
        system: "ethereum" as const,
        networkId: network.chainId,
        darkMode: true,
        mobilePosition: "top" as const,
        desktopPosition: "topRight" as const,
        // transactionHandler: (txInformation: TransactionEvent) => {
        //   console.log("Handled tx", txInformation);
        // },
      };
      const notify = Notify(options);

      let etherscanNetwork = "";
      if (network.name && network.chainId > 1) {
        etherscanNetwork = network.name + ".";
      }

      let etherscanTxUrl = "https://" + etherscanNetwork + "etherscan.io/tx/";
      if (network.chainId === 100) {
        etherscanTxUrl = "https://blockscout.com/poa/xdai/tx/";
      }

      try {
        let result: T | TransactionResponse;
        if (tx instanceof Promise) {
          result = await tx;
        } else {
          if (!tx.gasPrice) {
            /* eslint-disable-next-line no-param-reassign */
            tx.gasPrice = gasPrice || parseUnits("4.1", "gwei");
          }
          if (!tx.gasLimit) {
            /* eslint-disable-next-line no-param-reassign */
            tx.gasLimit = BigNumber.from(120000);
          }
          result = await signer.sendTransaction(tx);
        }

        // if it is a valid Notify.js network, use that, if not, just send a default notification
        if ([1, 3, 4, 5, 42, 100].indexOf(network.chainId) >= 0) {
          if (result.hash) {
            const { emitter } = notify.hash(result.hash);
            emitter.on("all", transaction => ({
              onclick: () => window.open((etherscanUrl || etherscanTxUrl) + transaction.hash),
            }));
          }
        } else {
          notification.info({
            message: "Local Transaction Sent",
            description: result.hash,
            placement: "topRight",
          });
        }

        return result;
      } catch (e) {
        console.log(e);
        if (e instanceof Error) {
          console.log("Transaction Error:", e.message);
          notification.error({
            message: "Transaction Error",
            description: e.message,
          });
        }
        throw e;
      }
    };
  }
  return undefined;
}
