export const ETHTokenID = "0xb333e3142fe16b78628f19bb15afddaef437e72d6d7f5c6c20c6801a27fba6";

export const riderStatus = (joinStatus: string | undefined, withdrawStatus: string | undefined): string => {
  switch (joinStatus) {
    case "PENDING":
      return "Available";
    case "ONBOARDING_IN_PROGRESS":
      return "Deposit in progress";
    case "ONBOARDING_DONE":
      return "Deposit in progress";
    case "OFFBOARDING_IN_PROGRESS":
      return "Completed";
    case "OFFBOARDING_DONE":
      switch (withdrawStatus) {
        case "PENDING":
          return "Withdrawal in progress";
        case "ONBOARDING_IN_PROGRESS":
          return "Withdrawal in progress";
        case "ONBOARDING_DONE":
          return "Withdrawal in progress";
        case "OFFBOARDING_IN_PROGRESS":
          return "Completed";
        case "OFFBOARDING_DONE":
          return "Completed";
        case "CANCELLATION_DONE":
          return "Completed";
        default:
          return "Completed";
      }
    case "CANCELLATION_DONE":
      return "Cancelled";
    default:
      return "-";
  }
};
