// import { get } from "../helpers/request";
import { components } from "./api";

export const getGlobalInfo = (): Promise<components["schemas"]["GlobalInfo"]> => {
  // return get<components["schemas"]["GlobalInfo"]>(`/globalInfo`);

  const globalInfoString = `{
      "nextReallocationTime": "1633004393000",
      "contractAddresses": {
          "faucet": "0x0000000000000000000000000000000000000000"
      }
    }`

  const globalInfo = JSON.parse(globalInfoString)
  return globalInfo
};
