import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BigNumber } from "ethers";
import { components } from "../api/api";
import { getStrategies } from "../api/strategy";
import { setSelectedToken } from "./assetSlice";

interface IStrategySlice {
  strategies: components["schemas"]["Strategy"][];
  loading: boolean;
  sneakyLoading: boolean; // loading state used by auto refreshes
  // derived states
  totalInvestmentValue: string; // BigNumber.toString()
  totalStakedValue: string;
  totalEarning: string; // BigNumber.toString()
}

const initialState: IStrategySlice = {
  strategies: [],
  loading: false,
  sneakyLoading: false,
  // derived states
  totalInvestmentValue: "0",
  totalStakedValue: "0",
  totalEarning: "0",
};

export const fetchStrategies = createAsyncThunk(
  "strategy/fetchStrategies",
  async ({ tokenId, address }: { tokenId: number; address: string; sneaky?: boolean }) => {
    return getStrategies(tokenId, address).catch(_ => null);
  },
);

const strategySlice = createSlice({
  name: "strategy",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchStrategies.pending, (state, { meta }) => {
        if (meta.arg.sneaky) {
          state.sneakyLoading = true;
        } else {
          state.loading = true;
        }
      })
      .addCase(fetchStrategies.rejected, state => {
        state.loading = false;
        state.sneakyLoading = false;
      })
      .addCase(fetchStrategies.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sneakyLoading = false;
        if (!payload) {
          return;
        }
        state.strategies = payload;
        state.totalInvestmentValue = state.strategies
          .reduce(
            (total, current) =>
              current.shareEstimatedValue ? total.add(BigNumber.from(current.shareEstimatedValue)) : total,
            BigNumber.from(0),
          )
          .toString();
        state.totalStakedValue = state.strategies
          .reduce(
            (total, current) =>
              current.stakedEstimatedValue ? total.add(BigNumber.from(current.stakedEstimatedValue)) : total,
            BigNumber.from(0),
          )
          .toString();
        const totalEarning = state.strategies.reduce(
          (total, current) => (current.earnings ? total.add(BigNumber.from(current.earnings)) : total),
          BigNumber.from(0),
        );
        state.totalEarning = totalEarning.toString();
      })
      .addCase(setSelectedToken, state => {
        state.totalInvestmentValue = initialState.totalInvestmentValue;
        state.totalEarning = initialState.totalEarning;
        state.strategies = [];
      });
  },
});

export default strategySlice.reducer;
