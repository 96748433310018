import { post } from "../helpers/request";
import baseUrl from "./baseUrl";
import { components } from "./starkex_api";

// export const withdrawAsset = async (params: components["schemas"]["WithdrawOperationParams"]) => {
//   return post(`/withdrawAsset`, params);
// };

export const withdrawAsset = async (params: components["schemas"]["transfer"]) => {
  const body = { "signed_transfer_to_vault_0": params };
  console.log("withdraw body:", body);
  const res = post(`/withdraw`, body, `${baseUrl}/defi_express`);
  console.log("withdraw response:", res);
};
