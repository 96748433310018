
export enum OnboardingActionString {
    ONBOARDING = 'celer Onboarding',
    KEY_DERIVATION = 'celer STARK Key',
}

export interface OnboardingAction {
    action: OnboardingActionString;
}

export enum SigningMethod {
    Compatibility = 'Compatibility',   // picks intelligently between UnsafeHash and Hash
    UnsafeHash = 'UnsafeHash',         // raw hash signed
    Hash = 'Hash',                     // hash prepended according to EIP-191
    TypedData = 'TypedData',           // order hashed according to EIP-712
    MetaMask = 'MetaMask',             // order hashed according to EIP-712 (MetaMask-only)
    MetaMaskLatest = 'MetaMaskLatest', // ... according to latest version of EIP-712 (MetaMask-only)
    CoinbaseWallet = 'CoinbaseWallet', // ... according to latest version of EIP-712 (CoinbaseWallet)
    Personal = 'Personal',             // message signed with personal_sign
}

export enum SignatureTypes {
    NO_PREPEND = 0,
    DECIMAL = 1,
    HEXADECIMAL = 2,
    PERSONAL = 3,
}

export interface KeyPairWithYCoordinate extends KeyPair {
    publicKeyYCoordinate: string;
}

// Key pair, represented as hex strings, no 0x prefix.
export interface KeyPair {
    publicKey: string; // Required x-coordinate.
    publicKeyYCoordinate?: string; // Optional y-coordinate.
    privateKey: string;
  }