import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScreenWidth } from "../theme";

interface IWindowWidthState {
  isMobile: boolean;
  screenWidth: ScreenWidth;
}

const initialState: IWindowWidthState = {
  isMobile: false,
  screenWidth: ScreenWidth.L,
};

const windowWidthSlice = createSlice({
  name: "windowWidth",
  initialState,
  reducers: {
    saveWidth: (state, { payload }: PayloadAction<{ winWidth: number }>) => {
      const width = payload.winWidth;
      state.isMobile = width <= ScreenWidth.M;
      switch (true) {
        case width <= ScreenWidth.XS:
        case width <= ScreenWidth.S:
          state.screenWidth = ScreenWidth.S;
          break;
        case width <= ScreenWidth.M:
        case width <= ScreenWidth.L:
          state.screenWidth = ScreenWidth.L;
          break;
        default:
          state.screenWidth = ScreenWidth.XL;
          break;
      }
    },
  },
});

export const { saveWidth } = windowWidthSlice.actions;

export default windowWidthSlice.reducer;
