import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInvestmentHistory, getUserRideHistory, UserRideHistory } from "../api/history";

interface IInvestmentHistorySlice {
  histories: UserRideHistory[];
  hasMore: boolean;
  loading: boolean;
  currentPage: number;
}

const initialState: IInvestmentHistorySlice = {
  histories: [],
  hasMore: true,
  loading: false,
  currentPage: 0,
};

export const getUserRideHistoryThunk = createAsyncThunk(
  "investmentHistory/getUserRideHistory",
  async ({ starkKey, pageSize, pageNumber }: { starkKey: string; pageSize: number; pageNumber: number }) => {
    return getUserRideHistory(starkKey, pageSize, pageNumber);
  },
);

export const fetchInvestmentHistory = createAsyncThunk(
  "investmentHistory/fetchInvestmentHistory",
  async ({ address, page }: { address: string; page: number }) => {
    return getInvestmentHistory(address, page);
  },
);

const investmentHistorySlice = createSlice({
  name: "investmentHistory",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserRideHistoryThunk.pending, (state, action) => {
        state.loading = true;
        state.currentPage = action.meta.arg.pageNumber;
      })
      .addCase(getUserRideHistoryThunk.fulfilled, (state, { payload }) => {
        state.histories =
          payload.sort((history1, history2) => (history1.dp_tx_id ?? 0) - (history2.dp_tx_id ?? 0)) || [];
        state.hasMore = false;
        state.loading = false;
      })
      .addCase(getUserRideHistoryThunk.rejected, state => {
        state.loading = false;
      });
  },
});

export default investmentHistorySlice.reducer;
