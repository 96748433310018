import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getStarkexMainchainHistory, DepositWithdrawHistoryEntity } from "../api/history";

interface IMainchainHistorySlice {
  entries: DepositWithdrawHistoryEntity[];
  hasMore: boolean;
  loading: boolean;
  currentPage: number;
}

const initialState: IMainchainHistorySlice = {
  entries: [],
  hasMore: true,
  loading: false,
  currentPage: 0,
};

export const fetchMainchainHistory = createAsyncThunk(
  "mainchainHistory/fetchMainchainHistory",
  async ({ starkKey, page }: { starkKey: string | undefined; page: number }) => {
    const historyList = getStarkexMainchainHistory(starkKey || "", page);
    return historyList
  },
);

const mainchainHistorySlice = createSlice({
  name: "mainchainHistory",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMainchainHistory.pending, (state, action) => {
        state.loading = true;
        state.currentPage = action.meta.arg.page;
      })
      .addCase(fetchMainchainHistory.fulfilled, (state, { payload }: PayloadAction<DepositWithdrawHistoryEntity[]>) => {
        state.entries = payload ?? [];
        state.loading = false;
      })
      .addCase(fetchMainchainHistory.rejected, state => {
        state.loading = false;
      });
  },
});



export default mainchainHistorySlice.reducer;
