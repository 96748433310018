import { JsonRpcSigner } from "@ethersproject/providers";
import { keccak256 } from 'ethereum-cryptography/keccak';
import {
  KeyPairWithYCoordinate,
  OnboardingAction,
  OnboardingActionString,
  SigningMethod,
  SignatureTypes,
} from "./onboardingTypes"
import { stripHexPrefix, createTypedSignature } from "./helpers"
import { hexToBn } from "./util";
import { asSimpleKeyPair, asEcKeyPair } from "./crypto";

const KEY_DERIVATION_SUPPORTED_SIGNING_METHODS: SigningMethod[] = [
  SigningMethod.TypedData,
  SigningMethod.MetaMask,
  SigningMethod.MetaMaskLatest,
  SigningMethod.CoinbaseWallet,
  SigningMethod.Personal,
];

// On mainnet, include an extra onlySignOn parameter.
// const EIP712_ONBOARDING_ACTION_STRUCT = [
//   { type: 'string', name: 'action' },
//   { type: 'string', name: 'onlySignOn' },
// ];

// const EIP712_ONBOARDING_ACTION_STRUCT_STRING = (
//   'celer(' +
//   'string action,' +
//   'string onlySignOn' +
//   ')'
// );

const EIP712_ONBOARDING_ACTION_STRUCT_TESTNET = [
  { type: 'string', name: 'action' },
];
// const EIP712_ONBOARDING_ACTION_STRUCT_STRING_TESTNET = (
//   'celer(' +
//   'string action' +
//   ')'
// );
export default class Onboarding {
  readonly host: string;

  readonly networkId: number;

  readonly signer: JsonRpcSigner | undefined

  constructor(
    host: string,
    networkId: number,
    signer: JsonRpcSigner | undefined
  ) {
    this.host = host;
    this.networkId = networkId;
    this.signer = signer
  }

  async deriveStarkKey(
    signingMethod: SigningMethod = SigningMethod.TypedData,
  ): Promise<KeyPairWithYCoordinate> {
    if (!KEY_DERIVATION_SUPPORTED_SIGNING_METHODS.includes(signingMethod)) {
      throw new Error('Unsupported signing method for API key derivation');
    }

    const message: OnboardingAction = { action: OnboardingActionString.KEY_DERIVATION };

    const domain = {
      name: 'celer',
      version: '1',
      chainId: this.networkId,
    }

    const rawSignature = await this.signer?._signTypedData(
      domain,
      { [domain.name]: EIP712_ONBOARDING_ACTION_STRUCT_TESTNET },
      message) || ""

    const signature = createTypedSignature(rawSignature, SignatureTypes.NO_PREPEND);

    return keyPairFromData(Buffer.from(stripHexPrefix(signature), 'hex'));
  }
}

export function keyPairFromData(data: Buffer): KeyPairWithYCoordinate {
  if (data.length === 0) {
    throw new Error('keyPairFromData: Empty buffer');
  }
  const hashedData = keccak256(data);
  const hashBN = hexToBn(hashedData.toString('hex'));
  const privateKey = hashBN.iushrn(5).toString('hex'); // Remove the last five bits.
  return asSimpleKeyPair(asEcKeyPair(privateKey));
}

