import { FC } from "react";
import { createUseStyles } from "react-jss";
import { ActionModal } from "..";

const useStyles = createUseStyles({
  modalContent: {
    "& .ant-modal-body": {
      minHeight: 150,
    },
  },
});

interface JoinSuccessModalProps {
  isJoin: boolean;
  onAction: () => void;
  onCancel: () => void;
}

const JoinSuccessModal: FC<JoinSuccessModalProps> = ({ isJoin, onAction, onCancel }) => {
  const classes = useStyles();
  return (
    <div>
      <ActionModal
        title={isJoin ? "Deposit Success" : "Withdraw Success"}
        visible
        onCancel={onCancel}
        onAction={onAction}
        modalClassName={classes.modalContent}
      >
        {isJoin ? "Deposit Success" : "Withdraw Success"}
      </ActionModal>
    </div>
  );
};

export default JoinSuccessModal;
