import { GithubFilled, TwitterCircleFilled } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import "antd/dist/antd.css";
import { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import VConsole from "vconsole";
import { Header } from "../components";
import PageFooter from "../components/Footer";
import { ProviderModal } from "../components/modals";
import OpGuideModal from "../components/modals/OpGuideModal";
import WindingDownModal from "../components/modals/WindingDownModal";
import { remains } from "../constants/const";
import { WindingDownInfo } from "../constants/type";
import { DiscordCircleFilled, TelegramCircleFilled } from "../icons";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { closeModal, ModalName } from "../redux/modalSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { Media } from "../theme";
import { Theme, ThemeType } from "../theme/theme";
import { History, Invest } from "../views";

const { Content } = Layout;
if (process.env.REACT_APP_DEBUG) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const vConsole = new VConsole();
}

const useStyles = createUseStyles((theme: Theme) => ({
  app: {
    display: "flex",
    justifyContent: "center",
  },
  layout: {
    maxWidth: theme.contentMaxWidth,
    minWidth: theme.contentMinWidth,
    background: theme.bgColorPrimary,
    minHeight: "100vh",
    padding: "0 12px",

    "@global": {
      ".ant-card": {
        background: theme.bgColorSecondary,
      },
    },
  },
  nav: {
    display: "flex",
    alignItems: "center",
    fontWeight: theme.fontWeightBolder,
    marginTop: 10,
  },
  menu: {
    borderRadius: "12px",
    padding: "18px 0",
    background: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    flex: 1,
    marginBottom: 12,

    "& .ant-menu-item a": {
      color: "rgba(255, 255, 255, 0.4)",
      fontWeight: 400,
      fontSize: "24px",
      "&:hover": {
        color: theme.fontColorSecondary,
      },
    },

    "& .ant-menu-item-selected a": {
      color: "#ffffff",
    },
  },
  social: {
    marginLeft: "auto",
    fontSize: 24,
    "& span": {
      marginRight: 20,
    },
  },
  [Media.S]: {
    menu: {
      padding: "12px 4px 4px 4px",
      "& .ant-menu-item a": {
        fontSize: 16,
      },
      "& .ant-menu-item.ant-menu-overflow-item": {
        flexGrow: 0,
        lineHeight: 1.2,
        padding: "0 18px 0 0",

        "&::after": {
          display: "none",
        },
      },
    },
  },

  notification: {
    display: "flex",
    alignItems: "center",
    background: "#FFF",
    color: "#0A0A0A",
    fontWeight: 400,
    borderRadius: 12,
    gap: 7,
    marginRight: 24,
  },

  bell: {
    width: 13,
    height: 15,
    marginLeft: 12,
  },

  notificationContent: {
    margin: "8px 12px 8px 0px",
  },
}));

function Home(): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme<Theme>();
  const [route, setRoute] = useState("");
  const isMobile = theme.type === ThemeType.S;
  const { starkKey } = useWeb3Context();
  const [ isShowBlockAlert, setIsShowBlockAlert] = useState(false);
  const [isShowOPGuideAlert, setIsShowOPGuideAlert] = useState(false);
  const [windingDownInfo, setWindingDownInfo] = useState<WindingDownInfo | undefined>(undefined)

  const dispatch = useAppDispatch();

  useEffect(() => {
    const segments = location.pathname.split("/").filter(p => p);
    setRoute(segments[0]);
  }, [location]);

  const { showProviderModal } = useAppSelector(state => state.modal);
  const handleCloseProviderModal = () => {
    dispatch(closeModal(ModalName.provider));
  };

  useEffect(() => {
      if(starkKey) {
        const keyArr: Array<string> = [];
        const amountArr: Array<string> = [];
        const tokenArr: Array<string> = [];
        
        remains.forEach(item => {
          const pices = item.split(",")
          keyArr.push(pices[0].toLowerCase());
          amountArr.push(pices[1]);
          tokenArr.push(pices[2]);
        });

        const index = keyArr.indexOf(starkKey.toLowerCase());
         
        if(index >= 0) {
          const amount = amountArr[index];
          const tokenSymbol = tokenArr[index];
          setWindingDownInfo({amount, tokenSymbol});
          setIsShowBlockAlert(true);
        } else {
          setIsShowOPGuideAlert(true);
        }
        console.log(`starkKey: ${starkKey} is a remain`, amountArr, tokenArr)
      }
  }, [starkKey])

  return (
    <div className={classes.app}>
      <Layout className={classes.layout}>
        <Header />
        <Content className={classes.content}>
          {isMobile && (
            <div className={classes.notification} style={{ marginRight: 12, marginTop: 14 }}>
              <img className={classes.bell} src="./bell.svg" alt="notification" />
              <div className={classes.notificationContent}>
                If you want to use <b>optimistic rollup version</b> of Layer2.Finance, please visit{" "}
                <a href="https://app.l2.finance/" target="_blank" rel="noreferrer">
                  {" "}
                  here.
                </a>
              </div>
            </div>
          )}
          <div className={classes.nav}>
            <Menu className={classes.menu} selectedKeys={[route]} mode="horizontal">
              <Menu.Item key="invest">
                <Link to="/invest">Invest</Link>
              </Menu.Item>
              <Menu.Item key="history">
                <Link to="/history">History</Link>
              </Menu.Item>
            </Menu>
            {/* notification */}
            {!isMobile && (
              <div className={classes.notification}>
                <img className={classes.bell} src="./bell.svg" alt="notification" />
                <div className={classes.notificationContent}>
                  If you want to use <b>optimistic rollup version</b> of Layer2.Finance, please visit{" "}
                  <a href="https://app.l2.finance/" target="_blank" rel="noreferrer">
                    {" "}
                    here.
                  </a>
                </div>
              </div>
            )}
            {theme.type === ThemeType.S || (
              <div className={classes.social}>
                <DiscordCircleFilled onClick={() => window.open("https://discord.gg/uGx4fjQ", "_blank")} />
                <TelegramCircleFilled onClick={() => window.open("https://t.me/celernetwork", "_blank")} />
                <TwitterCircleFilled onClick={() => window.open("https://twitter.com/CelerNetwork", "_blank")} />
                <GithubFilled
                  onClick={() => window.open("https://github.com/celer-network/layer2-finance-v2-contracts", "_blank")}
                />
              </div>
            )}
          </div>
          <Switch>
            <Route path="/invest">
              <Invest />
            </Route>
            <Route path="/history">
              <History />
            </Route>
            <Redirect from="/" to="/invest" />
          </Switch>
        </Content>
        <PageFooter />
      </Layout>
      <ProviderModal visible={showProviderModal} onCancel={handleCloseProviderModal} />

      {windingDownInfo && <WindingDownModal visible={isShowBlockAlert} windingDownInfo={windingDownInfo} />}

      <OpGuideModal visible={isShowOPGuideAlert} onCancel={() => setIsShowOPGuideAlert(false)}/>
    </div>
  );
}

export default Home;
