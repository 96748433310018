import axios, { AxiosResponse } from "axios";
import { TrackJS } from "trackjs";
import { getRpcUrl } from "./env";

const url = getRpcUrl();

export const get = async <T>(path: string, rpcUrl = url): Promise<T> => {
  const getUrl = `${rpcUrl}${path}`
  const res = await axios.get(getUrl).then(getErrorHandler<T>(path));
  return res.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const post = async <T>(path: string, body: any, rpcUrl = url): Promise<T> => {
  const res = await axios.post(`${rpcUrl}${path}`, body).then(getErrorHandler<T>(path, body));
  console.log("response:",res)
  return res.data;
};

const getErrorHandler =
  <T>(path: string, body?: unknown) =>
  (response: AxiosResponse<T>) => {
    if (response.status >= 400) {
      TrackJS.track(`
      response to ${path} failed with status code ${response.status}
      request body: ${body}
      response: ${response.data}
      `);
    }
    return response;
  };
