import { Button, Modal } from "antd";
import { FC } from "react";
import { createUseStyles } from "react-jss";
import { WindingDownInfo } from "../../constants/type";

const useStyles = createUseStyles({
  modalContent: {
    padding: "0px 10px",
    width: "100%",
  },
  buttonText: {
    marginTop: "80px",
    marginLeft: "20px",
    marginRight: "20px",
    width: "100%",
    height: "40px",
  },
});

interface Properties {
  visible: boolean;
  windingDownInfo: WindingDownInfo;
}

const WindingDownModal: FC<Properties> = ({visible, windingDownInfo}) =>{
  const styles = useStyles();

  const {amount, tokenSymbol} = windingDownInfo;

  return (
    <Modal mask title="Warning" closable={false} footer={null} okType="primary" visible={visible}>
      <div className={styles.modalContent}>Layer2.finance&apos;s ZK version is winding down. Based on the blockchain data, you have {amount} {tokenSymbol} locked in the system, please contact our support so we can guide you to retrieve your tokens.
      </div>
      <Button type="primary" className={styles.buttonText} onClick={() => {
        window.open("https://form.typeform.com/to/WEApy5FO", "_blank")
      }}>
        Contact Support
      </Button>
    </Modal>
  );
}

export default WindingDownModal;
