import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Rider, getNextAvailableRiders, getAllRides, getJoinInfo } from "../api/rides";

interface IRiders {
  riders: Rider[];
  loading: boolean;
  depositSuccess:  boolean;
}

const initialState: IRiders = {
  riders: [],
  loading: false,
  depositSuccess: false
};

export const fetchRiders = createAsyncThunk("riders/fetchRiders", async ({ tokenId }: { tokenId: string }) => {
  return getNextAvailableRiders(tokenId).catch(_ => null);
});

export const fetchAllRiders = createAsyncThunk("riders/fetchAllRiders", async () => {
  return getAllRides().catch(_ => null);
});

export const fetchJoinInfo = createAsyncThunk(
  "riders/fetchJoinInfo",
  async ({ rideID, starkKey }: { rideID: number; starkKey: string }) => {
    return getJoinInfo(rideID, starkKey).catch(_ => null);
  },
);

const getAllRidesSlice = createSlice({
  name: "allRiders",
  initialState,
  reducers: {
    setDepositSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.depositSuccess = payload
    }

  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllRiders.pending, state => {
        state.loading = true;
      })
      .addCase(fetchAllRiders.rejected, state => {
        state.loading = false;
      })
      .addCase(fetchAllRiders.fulfilled, (state, { payload }) => {
        if (payload?.length && Array.isArray(payload)) {
          state.riders = payload;
        }
        state.loading = false;
      });
  },
});

// const getRiderSlice = createSlice({
//   name: "riders",
//   initialState,
//   reducers: {},
//   extraReducers: builder => {
//     builder
//       .addCase(fetchRiders.pending, state => {
//         state.loading = true;
//       })
//       .addCase(fetchRiders.rejected, state => {
//         state.loading = false;
//       })
//       .addCase(fetchRiders.fulfilled, (state, { payload }) => {
//         if (payload?.length) {
//           state.riders = payload;
//         }
//         state.loading = false;
//       });
//   },
// });

export const {
  setDepositSuccess
} = getAllRidesSlice.actions
export default getAllRidesSlice.reducer;
