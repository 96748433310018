import { BigNumber } from "@ethersproject/bignumber";
import fromExponential from "from-exponential";
import { Button, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useMemo, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { useToggle } from "react-use";
import { InfoCircleOutlined } from "@ant-design/icons";
import { safeParseUnits } from "celer-web-utils/lib/format";
import { components } from "../api/api";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { Theme, ThemeType } from "../theme/theme";
import CustomTable from "./CustomTable";
import { ActionModal, ExitModal, FundModal } from "./modals";
import ActionTitle from "./modals/common/ActionTitle";
import { fetchAllRiders, setDepositSuccess } from "../redux/RiderSlice";
import { getVaultId } from "../api/vaultId";
import { joinRide, Rider, getJoinInfo } from "../api/rides";
import { starkEc, getLimitOrderMsgHashWithFee, sign } from "../starkex/signature";
import JoinAmountModal from "./modals/invest/JoinAmountModal";
import { ETHTokenID, riderStatus } from "../helpers/riderUtils";
import { useContractsContext } from "../providers/ContractsContextProvider";
import { getWithdrawAssets } from "../api/asset";
import JoinSuccessModal from "./modals/invest/JoinSuccessModal";
import { formatDecimal } from "../helpers/format";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    "@global": {
      ".ant-table-row": {
        height: 83, // because some strategy has second row to show farming button
      },
    },
  },
  listHeader: {
    display: "flex",
    fontSize: theme.fontSizeM,
    padding: theme.type === ThemeType.S ? "16px 0" : 24,
  },
  actions: {
    display: theme.type === ThemeType.S ? undefined : "flex",
    justifyContent: theme.type === ThemeType.S ? undefined : "flex-end",
    "@global": {
      ".ant-btn": {
        fontSize: theme.fontSizeM,
      },
    },
  },
  actionPending: {
    display: "flex",
    flexDirection: theme.actflexDirection,
    justifyContent: theme.invlexDirection,
    alignItems: theme.actalignItems,
    margin: "-12px 0",

    "@global": {
      ".ant-btn.ant-btn-link": {
        padding: 0,
        height: "auto",
      },
    },
  },
  titleSecondRow: {
    minHeight: 18,
  },
  protocolHeader: {
    fontSize: theme.fontSizeS,
    color: theme.inverseFontColorSecondary,
    marginBottom: 4,
  },
  protocols: {
    display: "flex",
    flexWrap: "nowrap",
  },
  protocol: {
    marginRight: 12,
    color: theme.inverseFontColorSecondary,
  },
  table: {
    margin: "0 -14px",
    padding: "0 24px 24px 24px",
  },
  highlight: theme.highlightedText,
  countdown: {
    color: theme.fontColorTertiary,
  },
}));

const curveTokenSwapTexts = {
  DAI: "USDC and USDT",
  USDC: "DAI and USDT",
  USDT: "DAI and USDC",
};

export default function StrategyTable(): JSX.Element {
  const theme = useTheme<Theme>();
  const isMobile = theme.type === ThemeType.S;
  const classes = useStyles();
  const { address, keyPairs } = useWeb3Context();
  const dispatch = useAppDispatch();
  const [showFund, toggleFund] = useToggle(false);
  const [showExit, toggleExit] = useToggle(false);
  const [selectedStrategy] = useState<components["schemas"]["Strategy"]>({});
  const [showCurveWarning, setShowCurveWarning] = useState(false);
  const { asset, ridersList } = useAppSelector(state => state);
  const { assets, selectedIndex } = asset;
  const { l2balance } = useAppSelector(state => state);
  const { balance } = l2balance;
  const { riders, loading } = ridersList;
  const token = assets[selectedIndex];
  const outputTokenIDs = useMemo(() => {
    if(riders.length) {
      console.log("riders", riders)
      return Array.from(new Set(riders.map(it => it.output_token_id)));
    }
    return []
  }, [riders])
  const selectedRiders = outputTokenIDs
    .map(outputTokenID => {
      return riders
        .filter(rider => rider.input_token_id === token.tokenId)
        .filter(rider => rider.output_token_id === outputTokenID)
        .reduce((p: Rider, v: Rider) => {
          return (p.ride_id ?? 0) > (v.ride_id ?? 0) ? p : v;
        }, {});
    })
    .filter(it => (it.ride_id ?? 0) > 0)
    .sort((p, v) => (v.ride_id ?? 0) - (p.ride_id ?? 0));

  const withdrawRiders = riders.filter(rider => rider.output_token_id === token.tokenId);
  const [selectedRide, setSelectedRide] = useState<Rider>();
  const [showJoinAmountModal, toggleShowJoinAmountModal] = useToggle(false);
  const { starkContract } = useContractsContext();
  const [isJoinAmountModal, setIsJoinAmountModal] = useState(true);
  const [showsJoinSuccessModal, setShowsJoinSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawAssets, setWithdrawAssets] = useState<components["schemas"]["Asset"][]>([]);
  const joiningToken = isJoinAmountModal
    ? token
    : withdrawAssets.find(it => it.tokenId === selectedRide?.input_token_id) ?? token;
  const [joinRideFailed, setJoinRideFailed] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const result = await getWithdrawAssets(address);
      setWithdrawAssets(result);
    })();
  }, [address]);

  useEffect(() => {
    if (showJoinAmountModal) return;
    dispatch(fetchAllRiders());
    // dispatch(fetchRiders({ tokenId: STARK_TOKEN_ID }));
    // dispatch(fetchStrategies({ address, tokenId: token.id }));
  }, [address, token.id, dispatch, showJoinAmountModal]);

  useEffect(() => {
    const show = !!selectedStrategy.protocols?.filter(protocol => protocol.name === "Curve").length;
    setShowCurveWarning(show);
  }, [selectedStrategy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showFund]);

  // const handleOpenProviderModal = useCallback(() => {
  //   dispatch(openModal(ModalName.provider));
  // }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function selectRide(ride: Rider | undefined) {
    if (ride === undefined) return;
    setSelectedRide(ride);
    toggleShowJoinAmountModal();
  }

  // const renderActions = useCallback(
  //   (strat: components["schemas"]["Strategy"]) => {
  //     if (!address) {
  //       // Not connected
  //       return (
  //         <div className={classes.actions}>
  //           <Button type="primary" onClick={handleOpenProviderModal}>
  //             Connect Wallet
  //           </Button>
  //         </div>
  //       );
  //     }

  //     if (strat.available) {
  //       return (
  //         <div className={classes.actions}>
  //           <Row gutter={8}>
  //             <Col span={12}>
  //               <Button
  //                 block
  //                 size={isMobile ? "large" : "middle"}
  //                 type="primary"
  //                 disabled={!signer}
  //                 onClick={() => {
  //                   setSelectedStrategy(strat);
  //                   toggleFund();
  //                 }}
  //               >
  //                 Deposit
  //               </Button>
  //             </Col>
  //             <Col span={12}>
  //               <Button
  //                 block
  //                 size={isMobile ? "large" : "middle"}
  //                 type={isMobile ? "primary" : "text"}
  //                 disabled={!signer || lte(strat.shareAmt, 0)}
  //                 onClick={() => {
  //                   toggleExit();
  //                   setSelectedStrategy(strat);
  //                 }}
  //               >
  //                 Withdraw
  //               </Button>
  //             </Col>
  //           </Row>
  //         </div>
  //       );
  //     }
  //     // Not available
  //     return (
  //       <div className={classes.actionPending}>
  //         <div style={{ marginLeft: -6 }}>
  //           <LabelWithPopover label="Aggregation Pending" iconOnLeft placement="left">
  //             Your fund allocation intent is confirmed on L2 and Layer2.Finance is waiting for the next aggregated fund
  //             allocation execution.
  //           </LabelWithPopover>
  //         </div>
  //         <div>
  //           <Button type="link" key="button1">
  //             <Link to="/history/investment">Check History</Link>
  //           </Button>
  //         </div>
  //       </div>
  //     );
  //   },
  //   [address, classes, handleOpenProviderModal, signer, toggleExit, toggleFund, isMobile],
  // );

  const columns = useMemo(() => {
    const _columns: ColumnsType<Rider> = [
      {
        title: "Strategy",
        dataIndex: "ride_id",
        key: "ride_id",
        render: (_, ride) => {
          const inputTokenID = ride.input_token_id;
          const outputTokenID = ride.output_token_id;
          const allAssets = [...assets, ...withdrawAssets];
          const inputTokenName = allAssets.find(it => it.tokenId === inputTokenID)?.name ?? "Unknown";
          const outputTokenName = allAssets.find(it => it.tokenId === outputTokenID)?.name ?? "Unknown";
          return <Typography>{`Compound St (${inputTokenName} -> ${outputTokenName})`}</Typography>;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, ride) => {
          const availableWithdrawRiders = withdrawRiders.filter(it => it.input_token_id === ride.output_token_id);
          const maxWithdrawRideID = Math.max(...availableWithdrawRiders.map(it => it.ride_id ?? 0));
          const withdrawRide = availableWithdrawRiders.find(it => it.ride_id === maxWithdrawRideID);
          const isLastestVisibleRide =
            ride.ride_id ===
            Math.max(
              ...selectedRiders
                .filter(it => it.output_token_id === withdrawRide?.input_token_id)
                .map(it => it.ride_id ?? 0),
            );

          const rideStatus = riderStatus(ride.status, isLastestVisibleRide ? withdrawRide?.status : undefined)

          let depositToolTips 
          let withdrawToolTips

          console.log("rideStatus", rideStatus)
          if (rideStatus === "Deposit in progress") {
            depositToolTips = "You deposit request has been submitted and is waiting for others to join the pooling. It may take up to 72 hours to finish the pooling and execute your deposit request.";
          } else if(rideStatus === "Withdrawal in progress") {
            withdrawToolTips = "You withdrawal request has been submitted and is waiting for others to join the pooling. It may take up to 72 hours to finish the pooling and execute your withdrawal request.";
          }

          const tooltipText = depositToolTips ?? withdrawToolTips;
          
          return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
              <Typography>
                {rideStatus}
              </Typography>

              { tooltipText ? (
                <Tooltip placement="bottom" title={tooltipText} color="#FFFFFF" overlayInnerStyle={{ color: "#0A1E42" }}>
                  <InfoCircleOutlined style={{ color: "#FFFFFFCC", fontSize: 13, marginLeft: 5 }} />
                </Tooltip>
              ): ""}
           
            </div>
          );
        },
      },
      {
        dataIndex: "actions",
        key: "actions",
        render: (_, ride) => {
          const availableWithdrawRiders = withdrawRiders.filter(it => it.input_token_id === ride.output_token_id);
          const maxWithdrawRideID = Math.max(...availableWithdrawRiders.map(it => it.ride_id ?? 0));
          const withdrawRide = availableWithdrawRiders.find(it => it.ride_id === maxWithdrawRideID);
          const isLastestVisibleRide =
            ride.ride_id ===
            Math.max(
              ...selectedRiders
                .filter(it => it.output_token_id === withdrawRide?.input_token_id)
                .map(it => it.ride_id ?? 0),
            );
          return (
            <div>
              <Button
                type="primary"
                disabled={ride.status !== "PENDING"}
                style={{ width: 110, margin: 5 }}
                onClick={() => {
                  setIsJoinAmountModal(true);
                  selectRide(ride);
                }}
              >
                Deposit
              </Button>
              <Button
                type="primary"
                // disabled={withdrawRide?.status !== "PENDING"}
                disabled
                style={{
                  display: isLastestVisibleRide && withdrawRide !== undefined ? "inline" : "none",
                  width: 110,
                  margin: 5,
                }}
                onClick={() => {
                  setIsJoinAmountModal(false);
                  selectRide(withdrawRide);
                }}
              >
                Withdraw
              </Button>
            </div>
          );
        },
      },
      {
        title: "Amount",
        dataIndex: "ride_id",
        key: "ride_id",
        render: (_, ride) => {
          const availableWithdrawRiders = withdrawRiders.filter(it => it.input_token_id === ride.output_token_id);
          const maxWithdrawRideID = Math.max(...availableWithdrawRiders.map(it => it.ride_id ?? 0));
          const withdrawRide = availableWithdrawRiders.find(it => it.ride_id === maxWithdrawRideID);
          const joiningTokena = withdrawAssets.find(it => it.tokenId === withdrawRide?.input_token_id) ?? token;

          const tokenAmount =
            Object.values(balance)
              .filter(it => (it.balance ?? 0) > 0)
              .find(it => joiningTokena.tokenId.includes(it.token_id?.substring(2) ?? ""))?.balance ?? 0;

          const amountStr = formatDecimal(
            tokenAmount || 0,
            joiningTokena.tokenId === ETHTokenID ? 8 : joiningTokena.decimal,
          );

          return <Typography>{`${amountStr} ${joiningTokena.symbol}`}</Typography>;
        },
      },
    ];
    return _columns;
  }, [assets, selectRide, selectedRiders, withdrawAssets, withdrawRiders, balance, token]);

  interface JoinRideResult {
      // eslint-disable-next-line
      dp_tx_id: number,
  }

  const { starkKey } = useWeb3Context();
  function prepareToJoinRide(ride: Rider | undefined, amount: number) {
    // exclude 1% fee
    const amountWithFee = isJoinAmountModal ? amount * 0.99 : amount
    const rideID = ride?.ride_id ?? 0;
    let price = ride?.price ?? 0;
    if (price === 0) {
      price = 1;
    }
    setIsLoading(true);
    getJoinInfo(rideID, starkKey || "").then(response => {
      (async () => {
        const vaultIdInput = response.vault_id_input;
        const vaultIdOutput = response.vault_id_output;
        const vaultIdShares = response.vault_id_ride_shares;
        const inputTokenID = response.ride_parameters.input_token_id;
        const outputTokenID = response.ride_parameters.output_token_id;
        const sharesTokenID = response.ride_parameters.ride_shares_token_id;
        const inputTokenQuantum = (await starkContract?.getQuantum(inputTokenID)) ?? BigNumber.from("1");
        const outputTokenQuantum = (await starkContract?.getQuantum(outputTokenID)) ?? BigNumber.from("1");
        const sharesTokenQuantum = (await starkContract?.getQuantum(sharesTokenID)) ?? BigNumber.from("1");
        price = price * inputTokenQuantum.toNumber() / outputTokenQuantum.toNumber(); // quantanized price to actual price
        const inputAmount = amountWithFee;
        const outputAmount = strip(amountWithFee / (price /(1 - response.ride_parameters.slippage)));
        const sharesAmount = amountWithFee;
        const onBoardFeeVaultId = await (await getVaultId(starkKey || "", response.ride_parameters.onboard_fee_token_id)).vault_id
        const offBoardFeeVaultId = await (await getVaultId(starkKey || "", response.ride_parameters.offboard_fee_token_id)).vault_id
        const cancelFeeVaultId = await (await getVaultId(starkKey || "", response.ride_parameters.cancelled_ride_fee_token_id)).vault_id
        const onboardLimitOrder = getOrder(
          vaultIdInput,
          vaultIdShares,
          inputTokenID,
          sharesTokenID,
          safeParseUnits(fromExponential(inputAmount), joiningToken.decimal ?? 18).div(inputTokenQuantum).toNumber(),
          safeParseUnits(fromExponential(sharesAmount), joiningToken.decimal ?? 18).div(sharesTokenQuantum).toNumber(),
          response.ride_parameters.onboard_fee_token_id,
          onBoardFeeVaultId ?? 0,
          safeParseUnits(fromExponential(sharesAmount), joiningToken.decimal ?? 18)
          .mul(response.ride_parameters.onboard_fee_to_sign)
          .div(sharesTokenQuantum).toNumber()
        );
        const cancelledLimitOrder = getOrder(
          vaultIdShares,
          vaultIdInput,
          sharesTokenID,
          inputTokenID,
          safeParseUnits(fromExponential(sharesAmount), joiningToken.decimal ?? 18).div(sharesTokenQuantum).toNumber(),
          safeParseUnits(fromExponential(inputAmount), joiningToken.decimal ?? 18).div(inputTokenQuantum).toNumber(),
          response.ride_parameters.cancelled_ride_fee_token_id,
          cancelFeeVaultId ?? 0,
          safeParseUnits(fromExponential(inputAmount), joiningToken.decimal ?? 18)
          .mul(response.ride_parameters.cancelled_ride_fee_to_sign)
          .div(inputTokenQuantum).toNumber(),
        );
        const offboardLimitOrder = getOrder(
          vaultIdShares,
          vaultIdOutput,
          sharesTokenID,
          outputTokenID,
          safeParseUnits(fromExponential(sharesAmount), joiningToken.decimal ?? 18).div(sharesTokenQuantum).toNumber(),
          safeParseUnits(fromExponential(outputAmount), joiningToken.decimal ?? 18).div(outputTokenQuantum).toNumber(),
          response.ride_parameters.offboard_fee_token_id,
          offBoardFeeVaultId ?? 0,
          safeParseUnits(fromExponential(outputAmount), joiningToken.decimal ?? 18)
          .mul(response.ride_parameters.offboard_fee_to_sign)
          .div(outputTokenQuantum).toNumber(),
        );
        joinRide(rideID, starkKey || "", onboardLimitOrder, cancelledLimitOrder, offboardLimitOrder).then(joinRideResponse => {
          setIsLoading(false);
          toggleShowJoinAmountModal();
          
          try{
            const result = joinRideResponse as JoinRideResult
            if(result.dp_tx_id){
              setShowsJoinSuccessModal(true);
            }else {
              setJoinRideFailed(true)
            }
           
          } catch(error) {
            setJoinRideFailed(true)
          }
        });
      })();
    });
  }

  function strip(num: number, precision = 12) {
    return +parseFloat(num.toPrecision(precision));
  }

  function getOrder(
    vaultIDSell: number,
    vaultIDBuy: number,
    tokenIDSell: string,
    tokenIDBuy: string,
    amountSell: number,
    amountBuy: number,
    feeToken: string,
    feeVaultId: number,
    feeLimit: number,
  ) {
    const nonce = parseInt((Date.now() / 1000).toString(), 10);
    const expirationTimestamp = 2000000;
    const msgHash = getLimitOrderMsgHashWithFee(
      vaultIDSell, // number
      vaultIDBuy, // number
      amountSell, // number
      amountBuy, // number
      tokenIDSell, // string
      tokenIDBuy, // string
      nonce, // number
      expirationTimestamp, // number
      feeToken, // string
      feeVaultId, // number
      feeLimit, // number
    );
    const privateKey = keyPairs?.privateKey;
    const keyPair = starkEc.keyFromPrivate(privateKey, "hex");
    const msgSignature = sign(keyPair, msgHash);
    const { r, s } = msgSignature;
    /* eslint-disable */
    const order = {
      stark_key: starkKey || "",
      vault_id_sell: vaultIDSell,
      vault_id_buy: vaultIDBuy,
      amount_sell: amountSell,
      amount_buy: amountBuy,
      token_id_sell: tokenIDSell,
      token_id_buy: tokenIDBuy,
      nonce: nonce,
      expiration_timestamp: expirationTimestamp,
      fee_token: feeToken,
      fee_vault_id: feeVaultId,
      fee_limit: feeLimit,
      signature: {
        r: `0x${r.toString(16)}`,
        s: `0x${s.toString(16)}`,
      },
    };
    return order;
  }

  const allAssets = [...assets, ...withdrawAssets];
  let allRiders = selectedRiders.filter(it => allAssets.find(asset => asset.tokenId === it.input_token_id))
  allRiders = selectedRiders.filter(it => allAssets.find(asset => asset.tokenId === it.output_token_id));

  return (
    <div className={classes.container}>
      <div className={classes.listHeader}>
        <h3>Investment Strategies</h3>
        {/* {riders && <Spin indicator={<LoadingOutlined style={{ fontSize: 12 }} />} style={{ marginLeft: 12 }} />} */}
      </div>
      {!isMobile ? (
        <CustomTable columns={columns} dataSource={allRiders} loading={loading} className={classes.table} />
      ) : (
        <CustomTable columns={columns} dataSource={allRiders} loading={loading} className={classes.table} />
      )}
      {showFund && showCurveWarning && (
        <ActionModal
          title={<ActionTitle title={`Deposit to ${selectedStrategy.name}`} />}
          onAction={() => setShowCurveWarning(false)}
          onCancel={() => toggleFund()}
          visible
        >
          <p>
            <Typography.Text>
              This strategy involves swapping your token to {curveTokenSwapTexts[token.symbol || ""]} in Curve 3Pool and
              provide liquidity and yield mining $CRV token.
            </Typography.Text>
          </p>
          <p>
            <Typography.Text>
              Due to inherent slippage and fluctuation of the underlying stable token price,{" "}
              <span className={classes.highlight}>your earning might be negative</span> for a certain period of time.
            </Typography.Text>
          </p>
        </ActionModal>
      )}
      {showFund && !showCurveWarning && <FundModal token={token} strategy={selectedStrategy} onClose={toggleFund} />}
      {showExit && <ExitModal token={token} strategy={selectedStrategy} onClose={toggleExit} />}
      {showJoinAmountModal && (
        <JoinAmountModal
          isJoin={isJoinAmountModal}
          isLoading={isLoading}
          strategy={selectedStrategy}
          token={joiningToken}
          onClickJoin={amount => prepareToJoinRide(selectedRide, amount)}
          onClose={toggleShowJoinAmountModal}
        />
      )}
      {showsJoinSuccessModal && (
        <JoinSuccessModal
          isJoin={isJoinAmountModal}
          onAction={() => {
            setShowsJoinSuccessModal(false);
            dispatch(setDepositSuccess(true));
          }}
          onCancel={() => setShowsJoinSuccessModal(false)}
        />
      )}

      <ActionModal
              visible={joinRideFailed}
              actionText="OK"
              title={<ActionTitle title="Deposit Failed" />}
              onCancel={() => setJoinRideFailed(false)}
              onAction={() => {
                setJoinRideFailed(false)
              }}
            >
              Deposit Failed, Please retry later.
      </ActionModal>

    </div>
  );
}
