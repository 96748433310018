import { ethers } from "ethers";
import { createContext, ReactChild, ReactChildren, useContext, useEffect, useState } from "react";
import { transactorWithNotifier } from "../helpers";
import { Transactor } from "../helpers/transactorWithNotifier";
import { useContractLoader } from "../hooks";
import useStarkContractLoader from "../hooks/startexContractLoader";
import { L2FContracts, l2fContracts } from "../hooks/contractLoader";
import { fetchGlobalInfo } from "../redux/globalInfoSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useWeb3Context } from "./Web3ContextProvider";
import { StarkExchange } from "../typechain/StarkExchange";

interface ContractsContextProps {
  contracts: L2FContracts;
  starkContract: StarkExchange | undefined,
  transactor: Transactor<ethers.ContractTransaction> | undefined;
}

interface ContractsContextProviderProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const ContractsContext = createContext<ContractsContextProps>({
  contracts: l2fContracts,
  starkContract: undefined,
  transactor: undefined,
});

export const ContractsContextProvider = ({ children }: ContractsContextProviderProps): JSX.Element => {
  const { provider } = useWeb3Context();
  const [addresses, setAddresses] = useState<Record<string, string>>({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchGlobalInfo());
  }, [dispatch]);

  const { faucetAddress } = useAppSelector(state => state.globalInfo);

  useEffect(() => {
    if (faucetAddress) {
      setAddresses({
        faucet: faucetAddress,
      });
    }
  }, [faucetAddress]);

  const contracts = useContractLoader(provider, addresses);

  const transactor = transactorWithNotifier<ethers.ContractTransaction>(provider);

  const { signer } = useWeb3Context()
  const starkContract = useStarkContractLoader(signer, `${process.env.REACT_APP_STARK_CONTRACT_ADDRESS}`)

  return (
    <ContractsContext.Provider
      value={{
        contracts,
        starkContract,
        transactor
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export const useContractsContext: () => ContractsContextProps = () => useContext(ContractsContext);
