import { get, post } from "../helpers/request";
import baseUrl from "./baseUrl";
import { components } from "./starkex_api";

/* eslint-disable camelcase */
export interface AvailableRiders {
  ride_id?: number;
  input_token_id?: string;
  output_token_id?: string;
  price?: number;
  slippage?: number;
  fee?: string;
}

export interface Rider {
  ride_id?: number;
  input_token_id?: string;
  output_token_id?: string;
  price?: number;
  slippage?: number;
  maximum_user_fee?: string;
  status?:
    | "PENDING"
    | "ONBOARDING_IN_PROGRESS"
    | "ONBOARDING_DONE"
    | "OFFBOARDING_IN_PROGRESS"
    | "OFFBOARDING_DONE"
    | "CANCELLATION_DONE";
}

export interface JoinInfo {
  vault_id_input: number;
  vault_id_ride_shares: number;
  vault_id_output: number;
  ride_parameters: {
    price: number;
    input_token_id: string;
    ride_shares_token_id: string;
    output_token_id: string;
    fee: string;
    slippage: number;
    onboard_fee_to_sign: number;
    onboard_fee_token_id: string;
    offboard_fee_to_sign: number;
    offboard_fee_token_id: string;
    cancelled_ride_fee_to_sign: number;
    cancelled_ride_fee_token_id: string;
  };
}

export async function getNextAvailableRiders(tokenId: string) {
  const res = await get<AvailableRiders[]>(
    `/get_next_available_rides?input_token_id=${tokenId}`,
    `${baseUrl}/defi_express`,
  );
  return res;
}

export async function getAllRides() {
  const res = await get<Rider[]>(`/get_all_rides?page_size=1000&page_number=0`, `${baseUrl}/defi_express`);
  return res;
}

export async function getJoinInfo(rideID: number, starkKey: string) {
  const response = await get<JoinInfo>(
    `/get_join_info?ride_id=${rideID}&stark_key=${starkKey}`,
    `${baseUrl}/defi_express`,
  );
  return response;
}

export async function getRideInfo(rideID: number) {
  const response = await get<JoinInfo>(`/get_ride_info?ride_id=${rideID}`, `${baseUrl}/defi_express`);
  return response;
}

export const joinRide = async (
  rideID: number,
  starkKey: string,
  onboardLimitOrder: components["schemas"]["l2_limit_order"],
  cancelledRideLimitOrder: components["schemas"]["l2_limit_order"],
  offboardLimitOrder: components["schemas"]["l2_limit_order"],
) => {
  const body = {
    ride_id: rideID,
    stark_key: starkKey,
    onboard_limit_order: onboardLimitOrder,
    cancelled_ride_limit_order: cancelledRideLimitOrder,
    offboard_limit_order: offboardLimitOrder,
  };
  const response = post("/join_ride", body, `${baseUrl}/defi_express`);
  return response;
};

export const addRide = async () => {
  const body = {
    line_id: 1,
    output_asset_amount_price: 10,
    slippage: 0.1,
    maximum_user_fee: "1",
    ride_shares_limit_order: {
      eth_address: "0xc1912fEE45d61C87Cc5EA59DaE31190FFFFf232d",
      vault_id_sell: 11,
      vault_id_buy: 12,
      amount_sell: "10000000",
      amount_buy: "1000000",
      token_id_sell: "0x774961c824a3b0fb3d2965f01471c9c7734bf8dbde659e0c08dca2ef18d56a",
      token_id_buy: "0xb333e3142fe16b78628f19bb15afddaef437e72d6d7f5c6c20c6801a27fba6",
      nonce: 0,
      expiration_timestamp: 438953,
    },
  };
  const response = post("/add_ride", body, `${baseUrl}/defi_express`);
  return response;
};
//   line_id: number;
//   output_asset_amount_price: number;
//   slippage: number;
//   maximum_user_fee: string;
//   ride_shares_limit_order: components["schemas"]["l1_limit_order"];
