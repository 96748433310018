import { parseEther } from "@ethersproject/units";
import { SignatureTypes } from "./onboardingTypes";

const ONE_SECOND_MS = 1000;
const ONE_HOUR_MS = 60 * 60 * ONE_SECOND_MS;

export function createTypedSignature(signature: string, sigType: number): string {
  if (!isValidSigType(sigType)) {
    throw new Error(`Invalid signature type: ${sigType}`);
  }
  return `${fixRawSignature(signature)}0${sigType}`;
}

export function isValidSigType(sigType: number): boolean {
  switch (sigType) {
    case SignatureTypes.NO_PREPEND:
    case SignatureTypes.DECIMAL:
    case SignatureTypes.HEXADECIMAL:
    case SignatureTypes.PERSONAL:
      return true;
    default:
      return false;
  }
}

/**
 * Fixes any signatures that don't have a 'v' value of 27 or 28
 */
export function fixRawSignature(signature: string): string {
  const stripped = stripHexPrefix(signature);

  if (stripped.length !== 130) {
    throw new Error(`Invalid raw signature: ${signature}`);
  }

  const rs = stripped.substr(0, 128);
  const v = stripped.substr(128, 2);

  switch (v) {
    case "00":
      return `0x${rs}1b`;
    case "01":
      return `0x${rs}1c`;
    case "1b":
    case "1c":
      return `0x${stripped}`;
    default:
      throw new Error(`Invalid v value: ${v}`);
  }
}

export function hexStringWithoutLeadingZero(input: string) {
  let stripped = stripHexPrefix(input);

  if (stripped.startsWith("0")) {
    stripped = stripped.replace(/^0+/, "");
  }

  return `0x${stripped}`;
}

// ============ Byte Helpers ============

export function stripHexPrefix(input: string) {
  if (input.indexOf("0x") === 0) {
    return input.substr(2);
  }
  return input;
}

export function toQuantumsExact(humanAmount: string, quantums: number): string {
  return toQuantumsHelper(humanAmount, quantums, true);
}

function toQuantumsHelper(humanAmount: string, quantums: number, assertIntegerResult: boolean): string {
  const amountBig = parseEther(humanAmount);
  const remainder = amountBig.mod(quantums);
  if (assertIntegerResult && !remainder.eq(0)) {
    throw new Error(`toQuantums: Amount ${humanAmount} is not a multiple of the quantum size ${quantums}`);
  }
  const amount = amountBig.div(quantums);
  return `${amount}`;
}

/**
 * Convert an ISO timestamp to an epoch timestamp in hours, rounding up.
 */
export function isoTimestampToEpochHours(isoTimestamp: string): number {
  return Math.ceil(new Date(isoTimestamp).getTime() / ONE_HOUR_MS);
}
