import { ButtonType } from "antd/lib/button";
import { useMemo } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import assert from "assert";

import { parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { useAppSelector } from "../../../redux/store";
import { withdrawAsset } from "../../../api/withdraw";
import { formatDecimal } from "../../../helpers/format";
import { gte, lt, lte, sub } from "../../../helpers/numbers";
import { IValidator, useTokenInputState } from "../../../helpers/tokenAmountState";
import { useContractsContext } from "../../../providers/ContractsContextProvider";
import { WithdrawalTransitionOperator } from "../../../hooks/operation/withdrawTransitionOperator";
import { useWeb3Context } from "../../../providers/Web3ContextProvider";
import { Theme } from "../../../theme/theme";
import LabelWithPopover from "../../LabelWithPopover";
import { ITokenInputChangeEvent } from "../../TokenInput";
import ActionModal from "../common/ActionModal";
import ActionTitle from "../common/ActionTitle";
import ModalExtraInfoRow from "../common/ModalExtraInfoRow";
import ModalResult from "../common/ModalResult";
import ModalTokenInput from "../common/ModalTokenInput";
import { DWModalProps } from "./DepositModal";
import { starkEc, getTransferMsgHash, sign, verify } from "../../../starkex/signature";
import { hexStringWithoutLeadingZero, isoTimestampToEpochHours } from "../../../starkex/helpers";
import { ETHTokenID } from "../../../helpers/riderUtils";

/* eslint-disable camelcase */

const useStyles = createUseStyles((theme: Theme) => ({
  heighlight: theme.highlightedText,
}));

export default function WithdrawModal({ token, onClose }: DWModalProps): JSX.Element {
  const classes = useStyles();
  const { starkContract } = useContractsContext();
  const history = useHistory();
  const { address, keyPairs, vaultIDs } = useWeb3Context();

  const { l2balance } = useAppSelector(state => state);

  const { balance } = l2balance;
  const availableAmount = balance.length ? balance[0].balance ?? 0 : 0;
  const bigBalanceAmount = parseUnits(availableAmount / 100000000 + "");
  const tokenAmount =
    Object.values(balance)
      .filter(it => (it.balance ?? 0) > 0)
      .find(it => token.tokenId.includes(it.token_id?.substring(2) ?? ""))?.balance ?? 0;
  const minWithdraw = useMemo(() => formatDecimal(token.minWithdraw, token.decimal, token.decimal), [token]);
  const validators: IValidator[] = useMemo(
    () => [
      {
        validator: amtBig => gte(amtBig, token.minWithdraw),
        message: `Please input a number larger than ${minWithdraw}`,
      },
      {
        validator: amtBig => gte(amtBig, token.withdrawFee?.amount),
        message: `Please input a number larger than the fee`,
      },
      {
        validator: amtBig => lte(amtBig, bigBalanceAmount),
        message: `You don't have enough L2 balance to withdraw`,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minWithdraw, token],
  );
  const [amount, setAmount] = useTokenInputState(token.decimal, undefined, validators);

  const { operation, signAndOperate, resetOperationState } = WithdrawalTransitionOperator(
    withdrawAsset,
  );

  const handleAction = async () => {
    const quantum = await starkContract?.getQuantum(token.tokenId) ?? BigNumber.from("1")
    const privateKey = keyPairs?.privateKey;
    const keyPair = starkEc.keyFromPrivate(privateKey, "hex");
    const publicKey = starkEc.keyFromPublic(keyPair.getPublic(true, "hex"), "hex");
    const expirationDate = "2022-12-17T04:15:55.028Z";
    const value = parseUnits(amount.input || "0", token.decimal).div(quantum);

    const withdrawalMsg = {
      amount: value.toString(),
      nonce: parseInt((Date.now() / 1000).toString(), 10),
      senderVaultId: vaultIDs.get(token.tokenId) ?? 0,
      token: token.tokenId,
      receiverVaultId: 0,
      receiverPublicKey: address,
      expirationTimestamp: isoTimestampToEpochHours(expirationDate),
      condition: "",
    };

    const msgHash = getTransferMsgHash(
      withdrawalMsg.amount, // - amount (uint63 decimal str)
      withdrawalMsg.nonce, // - nonce (uint31)
      withdrawalMsg.senderVaultId, // - sender_vault_id (uint31)
      withdrawalMsg.token, // - token (hex str with 0x prefix < prime)
      withdrawalMsg.receiverVaultId, // - target_vault_id (uint31)
      withdrawalMsg.receiverPublicKey, // - target_public_key (hex str with 0x prefix < prime)
      withdrawalMsg.expirationTimestamp, // - expiration_timestamp (uint22)
    );

    const msgSignature = sign(keyPair, msgHash);
    const { r, s } = msgSignature;
    assert(verify(publicKey, msgHash, msgSignature));
    signAndOperate({
      amount: Number(withdrawalMsg.amount),
      nonce: withdrawalMsg.nonce,
      sender_public_key: hexStringWithoutLeadingZero(keyPairs?.publicKey ?? ""),
      sender_vault_id: withdrawalMsg.senderVaultId,
      token_id: withdrawalMsg.token,
      receiver_public_key: withdrawalMsg.receiverPublicKey,
      receiver_vault_id: withdrawalMsg.receiverVaultId,
      expiration_timestamp: withdrawalMsg.expirationTimestamp,
      signature: {
        r: `0x${r.toString(16)}`,
        s: `0x${s.toString(16)}`,
      },
    });
  };

  const handleGoToHistory = () => {
    history.push("/history");
  };

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setAmount(e.value);
  };

  const handleModalClose = () => {
    resetOperationState();
    onClose();
  };

  const withdrawAmountRow = (
    <ModalExtraInfoRow key={1} left="Withdraw Amount" right={`${amount.formatted} ${token.symbol}`} />
  );
  const feeRow = (
    <ModalExtraInfoRow
      key={2}
      left={
        <LabelWithPopover label="Fee">
          This is the fee charged to cover the operation cost for this transaction.
        </LabelWithPopover>
      }
      right={`${formatDecimal(token.withdrawFee?.amount, token.decimal)} ${token.symbol}`}
    />
  );
  const youReceiveRow = (
    <ModalExtraInfoRow
      key={3}
      left={
        <LabelWithPopover label="You Receive on L1">
          This is the {token.symbol} you will receive in your L1 wallet after the fee is deducted.
        </LabelWithPopover>
      }
      right={`${
        lt(sub(amount.big, token.withdrawFee?.amount), 0)
          ? 0
          : formatDecimal(sub(amount.big, token.withdrawFee?.amount), token.decimal)
      } ${token.symbol}`}
    />
  );
  let content = (
    <ModalTokenInput
      description="You can withdraw your available L2 balance to your L1 wallet."
      amount={amount.input}
      maxAmount={formatDecimal(tokenAmount, token.tokenId === ETHTokenID ? 8 : token.decimal)}
      symbol={token.symbol}
      onChange={handleTokenInputChange}
      bottomDescription={`Minimal Withdraw Amount: ${minWithdraw} ${token.symbol}`}
    />
  );
  let action: () => void;
  let actionText: string;
  let buttonType: ButtonType;
  let extra;

  if (operation.completed) {
    content = (
      <ModalResult
        title="Withdrawal has been submitted"
        description={
          <span>
            In order to receive the fund in your L1 wallet, you will need to{" "}
            <span className={classes.heighlight}>manually confirm</span> the withdrawal again{" "}
            {process.env.REACT_APP_WITHDRAW_WINDOW}
            later in the history page.
          </span>
        }
      />
    );
    action = handleGoToHistory;
    actionText = "Check History";
    buttonType = "link";
    extra = [withdrawAmountRow, feeRow, youReceiveRow];
  } else {
    action = handleAction;
    actionText = "Withdraw";
    buttonType = "primary";
    extra = [feeRow, youReceiveRow];
  }

  return (
    <ActionModal
      visible
      title={<ActionTitle title="Withdraw to L1" token={token} />}
      actionText={actionText}
      actionDisabled={!amount.input}
      errMsg={amount.error}
      onCancel={handleModalClose}
      onAction={action}
      buttonType={buttonType}
      actionLoading={operation.loading}
      extra={extra}
    >
      {content}
    </ActionModal>
  );
}
