import { BigNumber } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { formatDecimal } from "./format";

export interface IAmount {
  input: string; // e.g. 123123
  big: BigNumber;
  formatted: string; // e.g. 123,123.00
  error: string;
}

export type ErrorHandler = (amount: BigNumber, setError: Dispatch<SetStateAction<string>>) => void;

export type Validator = (amount: BigNumber) => boolean;

export interface IValidator {
  validator: Validator;
  message: string;
}

export const useTokenInputState = (decimal = 18, formatKeepDecimals = 6, validators?: IValidator[]) => {
  const [inputString, setInputString] = useState("");
  const [bigNumber, setBigNumber] = useState(BigNumber.from(0));
  const [formattedString, setFormattedString] = useState("0");
  const [error, setError] = useState("");
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    const big = parseUnits(inputString.replace(",", "") || "0", decimal);
    setBigNumber(big);
    setFormattedString(formatDecimal(big, decimal, formatKeepDecimals));

    // if (validators && dirty) {
    //   const errMsg = applyValidators(big, validators);
    //   setError(errMsg);
    // }
  }, [decimal, dirty, formatKeepDecimals, inputString, validators]);

  const setInput = useCallback((input: string) => {
    setDirty(true);
    setInputString(input);
  }, []);

  const resetInput = useCallback(() => {
    setInputString("");
    setBigNumber(BigNumber.from(0));
    setFormattedString("0");
    setError("");
    setDirty(false);
  }, []);

  return [
    {
      input: inputString,
      big: bigNumber,
      formatted: formattedString,
      error,
    },
    setInput,
    resetInput,
  ] as [IAmount, Dispatch<SetStateAction<string>>, () => void];
};
