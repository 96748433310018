import { Provider } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";
import { InfuraProvider, JsonRpcProvider } from "@ethersproject/providers";
import { useEffect, useState } from "react";
import { StarkExchange__factory } from "../typechain/factories/StarkExchange__factory";
import { StarkExchange } from "../typechain/StarkExchange";

/**
 * Converts a Signer or Provider to a Signer.
 *
 * @param signerOrProvider A Signer or a Provider.
 * @returns A Signer.
 */
export async function ensureSigner(signerOrProvider: Signer | Provider): Promise<Signer | undefined> {
  let signer: Signer;
  let accounts: string[] = [];
  if (signerOrProvider && typeof (signerOrProvider as JsonRpcProvider).listAccounts === "function") {
    accounts = await (signerOrProvider as JsonRpcProvider).listAccounts();
  }

  if (accounts && accounts.length > 0) {
    signer = (signerOrProvider as JsonRpcProvider).getSigner();
  } else if (signerOrProvider instanceof InfuraProvider) {
    return undefined;
  } else {
    signer = signerOrProvider as Signer;
  }
  return signer;
}

export default function useStarkContractLoader(
  signerOrProvider: Signer | Provider | undefined,
  address: string,
): StarkExchange | undefined {
  const [constract, setContract] = useState<StarkExchange>();
  useEffect(() => {
    async function loadContracts() {
      if (typeof signerOrProvider !== "undefined") {
        try {
          const signer = await ensureSigner(signerOrProvider);
          if (!signer) {
            return;
          }
          const contract = StarkExchange__factory.connect(address, signerOrProvider);
          setContract(contract);
        } catch (e) {
          console.log("Error loading contracts", e);
        }
      }
    }
    loadContracts();
  }, [signerOrProvider, address]);
  return constract;
}
