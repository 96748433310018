export const remains = [
    "0x40b9db6db5baba39da87966a343e90e53aca982d8c1d135e4cf7fd62fb13383,0.01,ETH", // for testing
    "0x331b4be72c0088c12cd0fe6c5feaf9b8bb9b7c9ab61f386ddf956e6a8cb1d36,0.01,ETH",
    "0x28ab366aa46f22433e892b44ebfc8128a407846931f6563c3784dcf017aa5e3,0.000005,ETH",
    "0x191f60af4d28e597449c2a04338bc99603a28d8cf0a4d610cc1719c062166ab,0.0002,ETH",
    "0x4fb1050c738723fe0531e0edae7e419ca40c185bcdda710fa996de3295a293,0.01,ETH",
    "0x6858cdad032d6bea3fba45c06cb4bac1b8de3fb4217ec922c4303a7f472c999,0.020005,ETH",
    "0x637cfc6cdc828aaf7bb29b2d034025d9a2330a9845ad940ec1f0485453455d5,0.003,ETH",
    "0x7e4a13d16eb07660fbd984aa269fa34228d3d83f056b4d2ad5d3a69aa35efbe,0.0007,ETH",
    "0x7a852d3ec6ce6b9b3d01aaf3ab47ebb3451ed5926a1769a4b0d84c68c4483cd,0.001,ETH",
    "0x667051e4064f50969409be1993d5e75e87a84468de8bc422a65ecbc8cbe0b1c,0.0202055,ETH",
    "0x7adf0d1f4d4aa17b13b401ff4ffd16107e902de6acde1ef664261d97078b702,0.004,ETH",
    "0x41681c76479e0544ee91170ecdae3817f73804acdbf79499f46ee77e9907822,0.00000001,ETH",
    "0x702607497ce12e3949faaed6b200cfe6ca766d838e43cbbbe25d84652a8f1b1,0.00000001,ETH",
    "0x1d1c1d0e32b5389f7824575c9e2b0d5c9cda64f70d1f85923b09cf3b80c46ba,0.01,ETH",
    "0x5848dadad245fd521e7d8e1e73fec3639f2167d7e15fed392508b5dc6201836,0.0035,ETH",
    "0x774cf78e86f18a8e5e9a5e438a3cb1705a90d41ef5f5da0b1170894d266c7a4,0.00000008,ETH",
    "0x1661705d245b83f074b3d9888527866d219d75d48db43a4a6e63b997bbebe6e,0.002,ETH",
    "0xc47913ea0c42a002ac9c5b3356cdf5f4b7d20845c25bd1817577e7452dcc79,0.01,ETH",
    "0x1fe36d6d4a147010f1b6272a5ff31b0610aa2a63190e4f0b0e17e1e580f577f,0.0000006,ETH",
    "0x6442caabf31edc94f6950bd05f1597edc4df58b4391f3c802d3c526896e4add,0.001,ETH",
    "0x2a196ae8d76ee827977288ea6ea2924800b297753b9667dbfed23e3efa84f7a,0.0090001,ETH",
    "0x6deeffece7be902183f5030de7260c198e14548dfd1d6be86dfb35e8f40c7f3,0.002,ETH",
    "0x1d4830a4ac26f3e1bd373b305a71bba803c33ab189548b5ff89ee4d78fecaf2,0.001,ETH",
    "0x5507ba5c9a4bd266aea83a9ea2157a41d3b9bc7926f6fd8d18858a943da6f95,0.001,ETH",
    "0x6fabe382740d3a3ce689668f7158f0793f5861b9ea7bcdb713a77416df333e4,0.11,ETH",
    "0x5a5e10299564bd794bfc615bccd255b97ea5bd334aab79cd672f0ebeba69c1e,0.00000034,ETH",
    "0x371890b6909e11ef28b524eba1145735c4af093a7c646876fa7f4e1f110510e,0.01,ETH",
    "0x6d7aeb963aa2fb7fc108c7704e0ccfc81ab0a4e3e671c7b38b73e4b98806319,0.001,ETH",
    "0x4aab9c804eb097c73790752577c848a93caa237b77682209f57902703a6e114,0.01,ETH",
    "0x39f4937ef2032344c4ea2a6838b81a4621dbb07fe1346450789414df39e34b6,0.001,ETH",
    "0x12a664fae583595ddfbb12d8b33094b708304d79137cec39ea6220368facb9c,0.05,ETH",
    "0x4bc1ab51993b6b32d369e243582eab71e88f8de932dbb1a586221c73912151f,0.001,ETH",
    "0x398a11f858254e012bb4398062c4ff627b92dd976ecfba4df3869bf70d4ff55,0.01,ETH",
    "0x64b3c5b629dd6cc69a6a6e258d05d9433d768f920ea5d83893a5d49b5ffdf57,0.03,ETH",
    "0x286a857cfff9549214d167baca87d1e9e0fc7bdb85847a0989d003c6ea2d734,0.0000005,ETH",
    "0x18dbacbd368de577ce02e169ae440bced8f4192186dd840a49e680a399ef904,0.0000002,ETH",
    "0x111b080d2b900dc8fe4e7cda683a777ae85470c037256940fa8f59e5a06f16,0.003,ETH",
    "0xc7eb5a4be4fb288f60261f3c99e25dc50ce2dab0214692926982b639ba3f42,0.002,ETH",
    "0x277fdc8024fb6ad937c5bfd693ae2f184dc6cb6cc582fdbad750ce7f3203584,0.05,ETH",
    "0x5df30db1821663acf8d9992dbb7138fbb5beedf4a214b22665c5116be7ad80a,0.0003,ETH",
    "0x1e87e015926b0567d654bddd0b636d954a2a27bc6324e94b8d7b3c617e80282,0.002,ETH",
    "0x116ed6c59da9bc5311b5204e919eb0f146fc8b3cd6aa1b223843d9b9c2773a7,0.00000008,ETH",
    "0x4ae1bce358f205c11c4e68aaf780123cb2c228f48a895a3d6f5f9cbb2ce89a6,0.0000021,ETH",
    "0x22d962eeb62df2084625be0d83113331e4ee6164e57219fafbc62893d7e201d,0.001,ETH",
    "0x5a890089638f88da0035403c789f4046e7fbd6c868af03fd6a5fd52baf167e5,0.01,ETH",
    "0x6cc310a34290e219d1a2e2551daffda98a3e19996d3b890ad3a1d4881fb4921,0.0000001,ETH",
    "0x6858cdad032d6bea3fba45c06cb4bac1b8de3fb4217ec922c4303a7f472c999,2.46664283,WcETH",
    "0x702607497ce12e3949faaed6b200cfe6ca766d838e43cbbbe25d84652a8f1b1,0.49332856,WcETH",
    "0x28ab366aa46f22433e892b44ebfc8128a407846931f6563c3784dcf017aa5e3,2.46664283,WcETH",
    "0x286a857cfff9549214d167baca87d1e9e0fc7bdb85847a0989d003c6ea2d734,0.24666428,WcETH",
    "0x4ae1bce358f205c11c4e68aaf780123cb2c228f48a895a3d6f5f9cbb2ce89a6,1.03598998,WcETH",
    "0x667051e4064f50969409be1993d5e75e87a84468de8bc422a65ecbc8cbe0b1c,2.71330711,WcETH",
    "0x2a196ae8d76ee827977288ea6ea2924800b297753b9667dbfed23e3efa84f7a,0.04933285,WcETH",
    "0x116ed6c59da9bc5311b5204e919eb0f146fc8b3cd6aa1b223843d9b9c2773a7,0.03946628,WcETH",
    "0x6cc310a34290e219d1a2e2551daffda98a3e19996d3b890ad3a1d4881fb4921,0.04933285,WcETH",
    "0x41681c76479e0544ee91170ecdae3817f73804acdbf79499f46ee77e9907822,0.04933285,WcETH",
    "0x774cf78e86f18a8e5e9a5e438a3cb1705a90d41ef5f5da0b1170894d266c7a4,0.03946628,WcETH",
    "0x9d7990d98c51164c1cbe5cefe5ccad22014abcde4cf93f1c8c615768fcf42f,87584.42506241,WcUSDC",
    "0x5a890089638f88da0035403c789f4046e7fbd6c868af03fd6a5fd52baf167e5,0.000746,USDT",
    "0x7c89ab48680c89a5500ae33edda6afaf03bdbaa49573dd759d13b60a3b533cf,0.00022,USDT",
    "0x1e5a9298f03abc8885c8589dab5b3d2e46ac3ff05c847ef53de2fa987973775,0.000001,USDT",
    "0x7b629d5c68f33cf166c81866a7f5eef820ab3eeba1d6309725e5ff4064f6926,0.995105,USDT",
    "0x2e736193f1f6b1f8d54628900ece4b5240809d0e80ec10e5862380dd1fe1515,0.0015,USDT",
    "0x47635c350f73b4f8d92ab37048669e259fa339346dd8a59893322f165603307,5,USDT",
    "0x29f2c00eb341cd057df4d7727dab111887d179abe9b1be43aae04b4070897f1,0.0004,USDT",
    "0x437d00c6bed6373f3f8483a1abea3e08a5dc249d0dcd600da0bfeebc542876e,0.0001,USDT",
    "0x60e9b308bd74a82552ee26c16b1aaeae30664872257e766301e5c84eed4db05,0.0004,USDT",
    "0x28423da201427936eeca43cf478882cf74e5ea719b840f2679f0d5a5259aa64,0.000001,USDT",
    "0x68cb5876de7b9095c78bc00000eed51dfbf4316436ed57b58ef9dd44627690e,0.218148,USDT",
    "0x2fb73fc84662a71372aab69ec59ff55d36af1b3a9441067c4025ae2cce0dd8,0.0005,USDT",
    "0x32b0b89b9dd0edf91860ca3fa4f755688c4cdd96697131a793fd48f176cf371,1.336971,USDT",
    "0x1eaf6a573a287afcb35879f281e496f6d9c53df11bac480bde493a6ff0f88d5,0.005266,USDT",
    "0x7cfed2b82619922e99fb9e7550dc7cbeae1b8ce5ac042a258c867a967a24709,13.434052,USDC",
    "0x655e3f92976160881493e56e4e3d343d7699bbfde074d0c0f43625eec8a27ae,20,USDC",
    "0x71ee05302775fbd819b97a0738a7bf14dfd4c035def8c321ee9fb7f23b406c7,18.359494,USDC",
    "0x7b629d5c68f33cf166c81866a7f5eef820ab3eeba1d6309725e5ff4064f6926,1,USDC",
    "0x9d7990d98c51164c1cbe5cefe5ccad22014abcde4cf93f1c8c615768fcf42f,0.00002,USDC",
    "0x2f082493baf62d539b7fc6777d893753a5dc5544eacf553d661cc3ea31004f1,13.986445,USDC",
    "0x28ab366aa46f22433e892b44ebfc8128a407846931f6563c3784dcf017aa5e3,65,USDC",
    "0x3fa478764ab5e1b212353287333ce84a945c4dd72367fefa585175a9d8c1a88,8,USDC",
    "0x667051e4064f50969409be1993d5e75e87a84468de8bc422a65ecbc8cbe0b1c,25,USDC",
    "0x21a1871ca912537058b7dd534d08bd957389e6e39cb7fbef9f38d59904aacfb,5,USDC",
    "0x1e5a9298f03abc8885c8589dab5b3d2e46ac3ff05c847ef53de2fa987973775,899.82033401,WcUSDT",
    "0x437d00c6bed6373f3f8483a1abea3e08a5dc249d0dcd600da0bfeebc542876e,44.98669979,WcUSDT",
    "0x1eaf6a573a287afcb35879f281e496f6d9c53df11bac480bde493a6ff0f88d5,674.80049693,WcUSDT",
    "0x2fb73fc84662a71372aab69ec59ff55d36af1b3a9441067c4025ae2cce0dd8,224.93349897,WcUSDT",
    "0x70c369e6432cef9dfcecfba9f62d69a38785ff279c9a5e217722b5810e626db,99.7339959,WcUSDT",
    "0x7c89ab48680c89a5500ae33edda6afaf03bdbaa49573dd759d13b60a3b533cf,98.97073954,WcUSDT",
    "0x32b0b89b9dd0edf91860ca3fa4f755688c4cdd96697131a793fd48f176cf371,449.86699795,WcUSDT",
    "0x68cb5876de7b9095c78bc00000eed51dfbf4316436ed57b58ef9dd44627690e,224.93349897,WcUSDT",
    "0x5a890089638f88da0035403c789f4046e7fbd6c868af03fd6a5fd52baf167e5,335.51898647,WcUSDT",
    "0x2e736193f1f6b1f8d54628900ece4b5240809d0e80ec10e5862380dd1fe1515,674.80049693,WcUSDT",
    "0x60e9b308bd74a82552ee26c16b1aaeae30664872257e766301e5c84eed4db05,179.94679918,WcUSDT",
    "0x28423da201427936eeca43cf478882cf74e5ea719b840f2679f0d5a5259aa64,224.95167541,WcUSDT",
    "0x29f2c00eb341cd057df4d7727dab111887d179abe9b1be43aae04b4070897f1,179.94679918,WcUSDT"
];
