import assert from "assert";

/*
 Asserts input is equal to or greater then lowerBound and lower than upperBound.
 Assert message specifies inputName.
 input, lowerBound, and upperBound should be of type BN.
 inputName should be a string.
*/
export function assertInRange(input, lowerBound, upperBound, inputName = "") {
  const messageSuffix = inputName === "" ? "invalid length" : `invalid ${inputName} length`;
  assert(input.gte(lowerBound) && input.lt(upperBound), `Message not signable, ${messageSuffix}.`);
}
